import React, {useState} from 'react';
import {Typography} from "@mui/material";
import {RandomFloatFromInterval} from "../utils/Random";
import Box from "@mui/material/Box";

function PriceProduct({hasSale, price}) {
    const [sale] = useState(RandomFloatFromInterval(1.1, 1.14));
    return (
        <Box sx={{display: 'flex', flexDirection: 'row',mt:'3px'}}>
            <Typography
                        sx={{
                            fontWeight: 'bold',
                            borderRadius: '10px',
                            fontSize: '1.5rem',
                            color: 'black'
                        }}>
                {price} ₴
            </Typography>
            {hasSale ?
                <div style={{
                    borderRadius: '3px',
                    wordWrap: ''
                }}>
                    <Typography
                        sx={{
                            ml: '5px',
                            textDecoration: 'line-through',
                            color: 'gray',
                            display: 'inline',
                            fontSize: '1.2rem',
                        }}
                        >{parseFloat((price) * sale).toFixed(0)}₴
                    </Typography>
                </div> : <div style={{height: '19px'}}></div>
            }
        </Box>
    );
}

export default PriceProduct;
