import {Container, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import Textarea from "@mui/joy/Textarea";
import SendButton from "./buttons/SendButton";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import {MaskPhoneAdapter} from "./MaskPhoneAdapter";

export default function ContactForm(){
    const [isLoading, setLoading] = useState(false)
    const [inputUserName, setInputUserName] = useState('')
    const [inputText, setInputText] = useState('')
    const [inputPhoneNumber, setInputPhoneNumber] = useState('')

    function sendContactText(){
        setLoading(true)
        setInputUserName("")
        setInputPhoneNumber("")
        setInputText("")

        setTimeout(()=>{
            setLoading(false)
            fetch(`https://yellowshop.in.ua/contact-form.php?name=${inputUserName}&phone=${inputPhoneNumber}&comment=${inputText}`)
        },1500)
    }

    return(
        <Paper style={{padding: '20px 0 20px 20px',backgroundImage: 'linear-gradient(315deg, #0f1c3e 0%, #353c52 74%)'}}>
            <div style={{margin: '0 auto', maxWidth: '800px'}}>
                <Typography style={{margin: '0 auto', width: '80%', color: 'white'}} variant={'h5'}>
                    Написати нам:
                </Typography>
                <Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <FormControl sx={{width: '100%', maxWidth:'500px'}}>
                        <Input
                            size="lg"
                            value={inputUserName}
                            onChange={(event) => setInputUserName(event.target.value)}
                            placeholder="Прізвище та Ім'я"
                            startDecorator={<PersonRoundedIcon/>}
                            sx={{
                                marginTop: '1em',
                                backgroundColor: 'white',
                                borderRadius:'10px',
                                input: { color: 'black',opacity: 1 }
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{width: '100%', maxWidth:'500px'}}>
                        <Input
                            size="lg"
                            value={inputPhoneNumber}
                            onChange={(event) => setInputPhoneNumber(event.target.value)}
                            placeholder="Номер телефону"
                            startDecorator={<PermPhoneMsgIcon/>}
                            slotProps={{ input: { component: MaskPhoneAdapter } }}
                            sx={{
                                color:'black',
                                marginTop: '1em',
                                mt:1,backgroundColor: 'white',
                                borderRadius:'10px'
                            }}
                        />
                    </FormControl>
                    <Textarea
                        style={{margin: '0 auto',marginTop:'1em', width: '100%', maxWidth:'500px'}}
                        placeholder="Ваш текст"
                        variant="solid"
                        value={inputText}
                        minRows={8}
                        onInput={(e)=> setInputText(e.target.value)}
                        sx={{bgcolor:'white',color:'black',width: 'min(100%, 400px)', marginTop: '1em'}}
                    />
                    <SendButton
                        style={{margin: '0 auto',marginTop:'1em', width: '100%', maxWidth:'500px'}}
                        onClick={sendContactText}
                        loading={isLoading}
                        loadingIndicator="Відправлення..."
                        disabled={!(inputUserName && inputPhoneNumber && inputText)}
                        text={'Надіслати'}
                    />
                </Container>
            </div>
        </Paper>
    )
}