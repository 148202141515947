//Сторінка методів оплати
import styles from "../home/home.module.css";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import Box from "@mui/material/Box";
import PayAndDelyveryPoster from '../../assets/images/poster/payanddelivery.webp'

export default function PayAndDelyvery(){
    return(
        <div className={styles.home}
             style={{margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box
                sx={{maxWidth: '600px'}}>
                    <Paper elevation={3} style={{padding: 0}}>
                        <img src={PayAndDelyveryPoster} alt="Оплата та доставка" style={{height: 240,maxWidth: '100%', width: '100%'}}/>
                        <Box sx={{p: 2}}>
                                <Typography gutterBottom variant="h6" component="div">
                                    Оплата та доставка:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <Typography variant="body2">• <b>Служби доставки:</b> Укр Пошта, Нова пошта</Typography>
                                    <Typography variant="body2">• <b>Способи оплати:</b> Наложений платіж "Нова Пошта", Післяплата "Укр Пошта,"</Typography>
                                    <Typography variant="body2"> Оплата на розрахунковий рахунок ФОП(безготівковий розрахунок)</Typography>
                                    <Typography mt={2} variant="body2">При виборі <b>наложеного платежу</b> з доставкою <b>Нової пошти</b> вартість послуги з доставки складатиме 20 грн + 2% від оголошеної вартості товару (Плата Нової пошти за послугу "Наложений платіж")  + вартість доставки до отримувача(залежить від відстані та тарифів пошти)</Typography>
                                    <Typography mt={2} variant="body2">При виборі <b>післяплати</b> з доставкою <b>Укр пошти</b> вартість послуги з доставки складатиме 10 грн + 2% від оголошеної вартості товару (Плата Укр пошти за послугу "Післяплата")  + вартість доставки до отримувача(залежить від відстані та тарифів пошти)</Typography>
                                    <Typography mt={2} variant="body2">Покупець завжди має право відмовитись від посилки на пошті з будь яких причин. У такому випадку покупець <b>нічого не сплачує</b>, а ящо він вже сплатив за товар то має право <b>повернути кошти</b> зв'язавшись з продавцем.</Typography>
                                    <Typography mt={2} variant="body2">Доставка здійснюється по всій території України <b>окрім тимчасово окупованих територій та територій активних бойових дій</b>.</Typography>
                                </Typography>
                        </Box>
                    </Paper>
            </Box>
        </div>
    )
}