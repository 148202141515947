import * as React from 'react';
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const StyledButton = styled(Button)(({ customSx }) => ({
    ':hover': {
        color: 'white',
        backgroundColor: '#b60f0f',
    },
    ...customSx
}));

export default function ViewButton({ link, sx: customSx = {} }) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaign');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaign=${utm_campaign}` : ``;

    return (
        <StyledButton
            onClick={() => {
                navigate(`/products${link}${hasUtmCampaign}`);
                window.scrollTo(0, 0);
            }}
            size="small"
            variant="contained"
            startIcon={<ListAltIcon />}
            sx={{ mt: 1, backgroundColor: '#f15d14', ...customSx }}
        >
            Переглянути
        </StyledButton>
    );
}
