import CardMedia from "@mui/material/CardMedia";
import CarsImg from "../../assets/images/banners/nipels.webp"
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import styles from './carsblock.module.css'
import {Button} from "@mui/material";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

export default function CarsCard() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    return (
        <Card sx={{display: "flex"}}>
            <Box
                sx={{width: '50%'
            }}
            >
                <CardMedia
                    component="img"
                    sx={{ml: '10%', width: '80%', height: '100%'}}
                    image={CarsImg}
                    alt="cars"
                />
            </Box>
            <Box
                sx={{
                    width: '50%',
                    padding: 4,
                    backgroundColor: '#ede7a9',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Typography  sx={{fontWeight: 600, fontSize: 'large'}} component="div" variant="h8">
                   Авто товари
                </Typography>
                <Typography className={styles.hideInMobile} sx={{fontWeight: 400,mt:2,mb:2}} component="div" variant="h8">
                    Підберіть різноманітні аксесуари та корисні речі для свого авто. Брендові та захистні наліпки,
                    ніпелі, підсвітка, стікери, годинники та багато іншого прикрасять ваше авто та захистять від
                    пошкоджень.
                </Typography>

                <Button onClick={() => navigate(`/products/cars-products${hasUtmCampaign}`) } className={styles.viewButton} sx={{backgroundColor: '#f15d14', color: 'white',
                    ':hover': {
                        color: 'white',
                        backgroundColor: '#b60f0f',
                    },}}>
                    Переглянути
                </Button>
            </Box>
        </Card>
    );
}