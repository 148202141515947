import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import {Skeleton} from "@mui/material";

function ProductPageSkeleton(props) {
    return (
        <div>
            <Grid container>
                <Grid xs={12} md={6}>
                    <Skeleton style={{width: '100%', height: '300px', transform: 'none'}}/>
                </Grid>
                <Grid xs={12} md={6}>
                    <Skeleton style={{width: '100%', height: '300px', transform: 'none'}}/>
                </Grid>
            </Grid>
            <Grid mt={1} container rowSpacing={2} columnSpacing={2}>
                <Grid xs={12} md={6}>
                    <Skeleton style={{width: '100%', height: '300px', transform: 'none'}}/>
                </Grid>
                <Grid xs={12} md={6}>
                    <Skeleton style={{width: '100%', height: '300px', transform: 'none'}}/>
                </Grid>
            </Grid>

        </div>
    );
}

export default ProductPageSkeleton;