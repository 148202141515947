import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AutoProductsIcon from './icons/AutoProductsIcon'
import HairProductsIcon from './icons/HairIcon'
import AccessoriesIcon from './icons/AccessoriesIcon'
import AllProductsIcon from "./icons/AllProductsIcon";
import GridViewIcon from '@mui/icons-material/GridView';
import HomeIcon from '@mui/icons-material/Home';
import SupportIcon from '@mui/icons-material/Support';
import {Link, useSearchParams} from "react-router-dom";
import RefundIcon from "./icons/RefundIcon";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CallIcon from '@mui/icons-material/Call';

export default function MainMenu({handleDrawerClose}) {

    const [isCatalogOpen, setIsCatalogOpen] = React.useState(true);
    const [isSupportOpen, setIsSupportOpen] = React.useState(true);

    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    const handleIsCatalogClick = () => {
        setIsCatalogOpen(!isCatalogOpen);
    };

    const handleIsSupportClick = () => {
        setIsSupportOpen(!isSupportOpen);
    };

    return (
        <List
            sx={{width: '100%', maxWidth: 360, bgcolor: '#051E3433'}}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <Link onClick={handleDrawerClose} to={`/home${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>

            <ListItemButton>
                <ListItemIcon  sx={{minWidth: "40px"}}>
                    <HomeIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary="На головну"/>
            </ListItemButton>
            </Link>
            <ListItemButton onClick={handleIsCatalogClick} >
                <ListItemIcon sx={{minWidth: "40px"}}>
                    <GridViewIcon sx={{color: 'white'}}/>
                </ListItemIcon>

                <ListItemText primary="Каталог товарів"/>
                {isCatalogOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={isCatalogOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <Link onClick={handleDrawerClose} to={`/products${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <AllProductsIcon height={'25px'}/>
                            </ListItemIcon>
                            <ListItemText primary="Усі товари"/>
                        </ListItemButton>
                    </Link>

                    <Link onClick={handleDrawerClose} to={`/products/hairs${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <HairProductsIcon height={'25px'}/>
                            </ListItemIcon>
                            <ListItemText primary="Перуки"/>
                        </ListItemButton>
                    </Link>

                    <Link onClick={handleDrawerClose} to={`/products/accessories${hasUtmCampaign}`} style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <AccessoriesIcon height={'25px'}/>
                            </ListItemIcon>
                            <ListItemText primary="Аксесуари"/>
                        </ListItemButton>
                    </Link>

                    <Link onClick={handleDrawerClose} to={`/products/cars-products${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <AutoProductsIcon height={'25px'}/>
                            </ListItemIcon>
                            <ListItemText primary="Автотовари"/>
                        </ListItemButton>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleIsSupportClick} >
                <ListItemIcon sx={{minWidth: "40px"}}>
                    <SupportIcon sx={{color: 'white'}}/>
                </ListItemIcon>

                <ListItemText primary="Підтримка"/>
                {isSupportOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={isSupportOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <Link onClick={handleDrawerClose} to={`/contacts${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <CallIcon height={'25px'} style={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Контакти"/>
                        </ListItemButton>
                    </Link>

                    <Link onClick={handleDrawerClose} to={`/exchange${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <CurrencyExchangeIcon height={'25px'} style={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Обмін"/>
                        </ListItemButton>
                    </Link>

                    <Link onClick={handleDrawerClose} to={`/refund${hasUtmCampaign}` } style={{textDecoration: 'none', color: 'white'}}>
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <RefundIcon height={'25px'}/>
                            </ListItemIcon>
                            <ListItemText primary="Повернення"/>
                        </ListItemButton>
                    </Link>

                </List>
            </Collapse>
        </List>
    );
}

