import React from 'react';
import {Typography} from "@mui/material";

function Theses({theses}) {
    return (
        <div style={{paddingLeft: '20px', margin: '10px'}}>
            {theses.map((these, id) => <div key={id} style={{display: 'flex', justifyContent:'start'}}>
                                             <Typography sx={{mb:1}}>{these}</Typography>
                                       </div>
            )}
        </div>
    );
}

export default Theses;