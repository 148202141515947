//Сторінка повернення товарів
import styles from "../home/home.module.css";
import * as React from 'react';
import ScheduleWorkPoster from '../../assets/images/poster/schedulework.webp'
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function ScheduleWork(){
    return(
        <div className={styles.home}
             style={{margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box
                sx={{maxWidth: '500px'}}>
                <Paper elevation={3} style={{padding: 0}}>
                    <img src={ScheduleWorkPoster} alt="Оплата та доставка" style={{height: 150,maxWidth: '100%', width: '100%'}}/>
                    <Box sx={{p: 2}}>
                        <Typography gutterBottom variant="h4" component="div">
                            Графік роботи:
                        </Typography>
                        <Typography variant="h5" color="text.secondary">
                            <Typography variant="h5">з <b>Понеділка</b> по <b>Неділю</b></Typography>
                            <Typography variant="h5">з <b>9:00</b> до <b>21:00</b></Typography>
                            <Typography mt={1} mb={2} variant="h5">Без перерв та вихідних</Typography>
                            <Typography variant="h6">Замовлення та запитання що надійдуть у не робочий час, будуть опрацьовані на наступний день.</Typography>
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </div>
    )
}