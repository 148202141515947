import './slider.css'
import PropTypes from 'prop-types'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Thumbs} from 'swiper'
import {useState} from 'react'
import {Paper} from "@mui/material";

const ProductImagesSlider = ({images}) => {
    const [activeThumb, setActiveThumb] = useState()
    return (
        <Paper sx={{borderRadius: '0px', height: '100%'}}>
            <div style={{width: 'min(100%, 500px)', margin: '0 auto'}}>
                <Swiper
                    loop={images.length > 1}
                    spaceBetween={10}
                    navigation={false}
                    modules={[Navigation, Thumbs]}
                    grabCursor={true}
                    thumbs={{swiper: activeThumb, autoScrollOffset: 1}}
                    className='product-images-slider'
                >
                    {
                        images.map((item, index) => (
                            <SwiperSlide key={index} style={{display: 'flex', alignItems: 'center'}}>
                                <img style={{
                                    height: 'auto',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }} src={item}
                                     alt="product images"/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

                <div style={images.length === 1 ? {
                    height: '0px',
                    opacity: '0.01',
                    transition: 'height 500ms'
                } : {height: 'auto', transition: 'height 500ms'}}>
                    <Swiper
                        height={10}
                        onClick={setActiveThumb}
                        spaceBetween={0}
                        slidesPerView={4}
                        watchSlidesProgress
                        className='product-images-slider-thumbs'
                    >
                        {
                            images.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="product-images-slider-thumbs-wrapper">
                                        <img style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                             src={item} alt="product images"/>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </Paper>
    );
}

ProductImagesSlider.propTypes = {
    images: PropTypes.array.isRequired
}

export default ProductImagesSlider