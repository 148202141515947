import React, {useEffect, useRef, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import YoutubePlayer from "../../components/YoutubePlayer";
import styles from '../product/product.module.css'
import ProductPageSkeleton from "./localComponents/ProductPageSkeleton";
import ProductImagesSlider from "./localComponents/productImageSlider/ProductImagesSlider";
import AboutProduct from "./localComponents/aboutProduct/AboutProduct";
import OrderBlock from "./localComponents/orderBlock/orderBlock";
import {Paper} from "@mui/material";
import CommentsBlock from "../../components/comments/CommentsBlock";
import ReactGA from 'react-ga4';

import ReactFbq from 'react-fbq';

function Product(props) {

    const [searchParams] = useSearchParams();
    const productId = searchParams.get('id')

    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [product, setProduct] = useState()

    const [valueInSelect, setValueInSelect] = useState(false)
    const [imagesToShow, setImagesToShow] = useState()
    const buyRef = useRef(null)
    const productsUrl = `https://site.v2.yellowshop.in.ua/api/v1/products/all`

    useEffect(() => {
            valueInSelect &&
            setImagesToShow(product.images
                .sort((a, b) => a.priority - b.priority)
                .map(image => image.link)
            )
        }
        , [valueInSelect]
    )

    useEffect(()=> {
        ReactGA.initialize('G-L7YL6R9S7P')
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: `Product Page ID: ${productId}` });

        setLoading(true)
        fetch(productsUrl)
            .then(data => data.json())
            .then(products => {
                const product = products.filter(prod => prod.productId == productId)[0]
                setProduct(product)
                const firstCharacteristic = product.images[0].characteristic
                setValueInSelect(firstCharacteristic)
                setImagesToShow(product.images
                    .sort((a, b) => a.priority - b.priority)
                    .map(image => image.link)
                )

            })
            .catch(error => setError(error))
            .finally(()=>{
                setLoading(false)
            })
    }, [productId,productsUrl])

    if(!isLoading) {
        if(product.pixelID.includes(';')){
            let pixels = product.pixelID.split(';')
            pixels.forEach(pixel => ReactFbq.initialize({id: pixel}))

        }else{
            ReactFbq.initialize({id: product.pixelID});
        }
        ReactFbq.pageView();
    }

    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            {isLoading ?
                <ProductPageSkeleton/>
                :
                product &&
                <>
                    <Grid container>
                        <Grid xs={12} md={6} >
                            <ProductImagesSlider images={imagesToShow}/>
                        </Grid>
                        <Grid xs={12} md={6} >
                            <AboutProduct buyRef={buyRef}
                                          onChange={(e, newValue) => (setValueInSelect(newValue))}
                                          valueInSelect={valueInSelect}
                                          product={product}/>
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={2} columnSpacing={2} style={{marginTop: '20px'}}>
                        <Grid xs={12} md={6}>
                            <Paper style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundImage: "linear-gradient(315deg, #f39f86 0%, #f9d976 74%)"
                            }}>
                                <YoutubePlayer videoId={product.videoId}/>
                            </Paper>
                        </Grid>
                        <Grid xs={12} md={6} ref={buyRef}>
                            <OrderBlock selected={valueInSelect} product={product}/>
                        </Grid>
                    </Grid>
                    <CommentsBlock product={product}/>
                </>
            }
        </div>

    );
}

export default Product;