import * as React from "react"

const HairIcon = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         {...props}
         fill="#ffffff"
         viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g>
            <path d="M439.467,162.133c0-33.775-28.552-61.227-62.225-59.682c1.647-5.504,2.492-11.264,2.492-17.118
			C379.733,52.395,352.93,25.6,320,25.6c-15.94,0-31.002,6.391-42.044,17.331C265.293,17.033,238.865,0,209.067,0
			c-42.351,0-76.8,34.458-76.8,76.8c0,5.888,0.666,11.699,1.988,17.382c-34.577,3.294-61.722,32.512-61.722,67.951
			c0,23.543,11.947,43.554,33.041,55.782c-3.584,18.074-7.441,39.842-7.441,64.648c0,58.283,5.726,117.658,47.249,157.073
			c6.695,6.351,13.893,12.032,21.251,17.366v46.463c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-34.672
			c0.884,0.592,1.771,1.188,2.651,1.776l8.183,5.495c3.234,2.185,6.383,4.523,9.523,6.844c13.67,10.103,27.802,20.557,47.676,20.557
			s34.005-10.453,47.676-20.557c3.14-2.321,6.289-4.659,9.523-6.844l8.183-5.495c0.958-0.641,1.922-1.288,2.884-1.933v34.829
			c0,4.71,3.814,8.533,8.533,8.533c4.719,0,8.533-3.823,8.533-8.533v-46.63c7.277-5.286,14.393-10.915,21.018-17.199
			c41.523-39.415,47.249-98.79,47.249-157.073c0-24.03-3.618-45.201-7.108-62.942C422.246,211.652,439.467,188.339,439.467,162.133z
			 M123.563,215.023c0.114-0.565,0.163-1.13,0.163-1.69h0.007c0-24.218,52.565-51.2,128-51.2s128,26.982,128,51.2h0
			c0.001,0.563,0.056,1.129,0.17,1.69c3.721,18.483,8.363,41.498,8.363,67.541c0,2.346-0.01,4.71-0.027,7.081
			c-34.792-20.756-83.199-16.769-84.898-16.576c-44.689,0-104.013-64.819-104.61-65.468c-1.621-1.783-3.917-2.799-6.323-2.799
			c-33.804,0-59.824,27.774-75.667,50.981C118.418,240.6,121.177,226.876,123.563,215.023z M346.334,427.264
			c-6.624,6.282-13.985,11.98-21.563,17.385c-0.851,0.419-1.626,0.968-2.287,1.633c-4.923,3.456-9.918,6.799-14.849,10.098
			l-8.286,5.555c-3.413,2.321-6.758,4.787-10.095,7.253c-11.964,8.858-23.27,17.212-37.521,17.212s-25.557-8.354-37.521-17.212
			c-3.337-2.466-6.682-4.932-10.095-7.253l-8.286-5.555c-13.329-8.917-27.127-18.142-38.699-29.116
			c-36.122-34.298-41.344-86.445-41.879-134.515c3.265-7.118,32.138-67.401,73.578-70.742
			c12.954,13.594,67.823,68.105,115.217,68.096h0.043c0.552-0.058,54.651-4.443,83.678,20.061
			C385.979,353.058,378.128,397.076,346.334,427.264z M395.091,202.61c-10.631-33.012-69.788-57.544-143.358-57.544
			c-71.624,0-129.582,23.253-142.408,54.944C99.664,193.053,89.6,181.23,89.6,162.133c0-28.237,22.963-51.2,51.2-51.2
			c1.485,0,2.935,0.111,4.386,0.23c3.029,0.256,5.837-1.067,7.595-3.456c1.749-2.398,2.142-5.53,1.015-8.286
			c-2.961-7.228-4.463-14.839-4.463-22.622c0-32.939,26.803-59.733,59.733-59.733c27.52,0,51.336,18.671,57.924,45.414
			c0.836,3.405,3.678,5.948,7.151,6.417c3.439,0.444,6.886-1.246,8.585-4.301c7.552-13.525,21.828-21.931,37.274-21.931
			c23.526,0,42.667,19.14,42.667,42.667c0,8.559-2.526,16.819-7.296,23.876c-2.074,3.063-1.937,7.108,0.324,10.027
			c2.261,2.91,6.135,4.045,9.634,2.79c4.71-1.698,9.566-2.56,14.404-2.56c23.526,0,42.667,19.14,42.667,42.667
			C422.4,180.158,411.118,196.266,395.091,202.61z"/>
        </g>
    </svg>
)

export default HairIcon
