import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import ProductCard from "../../components/cards/ProductCard";
import {useEffect, useState} from "react";
import {Box, Skeleton} from "@mui/material";

export default function ProductsList(props) {
    const [products, setProducts] = useState()
    const [productsFiltered, setProductsFiltered] = useState()
    const [error, setError] = useState()
    const [isLoading, setLoading] = useState(false)

    const productsUrl = `https://site.v2.yellowshop.in.ua/api/v1/products/all`

    useEffect(() => {
        setLoading(true);
        fetch(productsUrl)
            .then(response => response.json())
            .then(productsList => {
                let filteredProducts = productsList.filter(product => product.enabled === true);
                if (props.category !== 'all') {
                    filteredProducts = productsList.filter(product => product.category === props.category && product.enabled === true);
                }
                setProductsFiltered(filteredProducts);
                setProducts(productsList);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [productsUrl, props.category]);

    return (
        <div className={styles.home} style={{margin: '0 auto', marginTop: '20px'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {
                    isLoading ?
                        <>
                            {new Array(9).fill(null).map((any, id) =>
                                <Grid key={id} xs={12} sm={6} md={3}>
                                    <Box>
                                        <Skeleton variant="rectangular" sx={{height: '365px', margin: '0'}}/>
                                    </Box>
                                </Grid>)}
                        </>
                        :
                        <>
                            {error && <div> Виникла якась помилка {error}</div>}
                            {products &&
                                productsFiltered.map((product, id) =>
                                    <Grid key={id} xs={12} sm={6} md={3}>
                                            <ProductCard {...product}/>
                                    </Grid>
                                )}
                        </>
                }
            </Grid>
        </div>
    )
}