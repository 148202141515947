import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    comments: {
        0 : [

        ],
    }
}


export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {

        addComment: (state, action) => {
            const id = parseInt(action.payload.id)
            const text = action.payload.text
            const userName = action.payload.userName

            if (!state.comments[id] )
                state.comments[id] = []

            state.comments[id].push({
                userName: userName,
                text: text
            })

        }
    }
})

export const {
    addComment,
} = commentsSlice.actions
export default commentsSlice.reducer