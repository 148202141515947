//Сторінка обміну товарів
import styles from "../home/home.module.css";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import RefundPoster from '../../assets/images/poster/refund.webp'
import Sender from '../../assets/senders.json'
import ContactForm from "../../components/ContactForm";
import {Paper} from '@mui/material';
import Box from "@mui/material/Box";

export default function Exchange() {
    return (
        <div className={styles.home}
             style={{margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box
                sx={{maxWidth: '600px'}}>
                <Paper elevation={3} sx={{padding: 0, mb: 2}}>
                    <img src={RefundPoster} alt="Повернення"
                         style={{height: 240, maxWidth: '100%', width: '100%'}}/>
                    <Box sx={{p: 2, maxWidth: '600px'}}>
                        <Typography gutterBottom variant="h6" component="div">
                            Умови обміну товару:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Typography variant="body2">• <a style={{color: 'red'}}
                                                             href="https://zakon.rada.gov.ua/laws/show/172-94-%D0%BF#Text">Товар
                                не належить до товарів, що не підлягають обміну та поверненню;🔗</a></Typography>
                            <Typography variant="body2">• Минуло менш як <b>14 днів</b> з моменту отримання
                                товару;</Typography>
                            <Typography variant="body2">• Товар не пошкоджений та у повній комплектації;</Typography>
                            <Typography variant="body2">• Не порушена цілісність пакування (відкрите упакування не
                                вважається пошкодженням)</Typography>
                            <Typography variant="body2">• Відправлена посилка <b>Без "Наложеного/Післяплати"
                                платежу</b> з дозволеним оглядом;</Typography>
                        </Typography>
                        <Typography mt={1} gutterBottom variant="h6" component="div">
                            Здійснення обміну товару:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Typography variant="body2">1. Надішліть нам товар <b>Новою Поштою</b> за
                                адресою:</Typography>
                            <Typography variant="body2">• <b>м.Запоріжжя</b>, просп. Соборний 43, відділення <b>№8</b>;</Typography>
                            <Typography variant="body2">• Отримувач: <b>{Sender[0].name}</b>;</Typography>
                            <Typography variant="body2">• Тел.: {Sender[0].phone}.</Typography>
                            <Typography variant="body2" mt={2}>2. Надішліть нам у <a style={{color: 'red'}}
                                                                                     href="viber://chat?number=%2B380966822934">Viber
                                (0966822934)🔗</a>:</Typography>
                            <Typography variant="body2"> • <b>Фото з номером ТТН виданим при відправленні товару до нас
                                на
                                пошті</b>;</Typography>
                            <Typography variant="body2">• <b>Причину обміну товару</b>.</Typography>
                        </Typography>
                        <Typography mt={1} gutterBottom variant="h6" component="div">
                            Терміни та гарантії:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Typography variant="body2">• При дотриманні умов продавець гарантує обмін
                                товару,</Typography>
                            <Typography variant="body2"><a href="https://zakon.rada.gov.ua/laws/show/1023-12#Text">згідно
                                закону України про захист прав споживачів</a>;</Typography>
                            <Typography variant="body2">• Обмін буде надіслано у день отримання товару на
                                обмін;</Typography>
                        </Typography>
                        <Typography mt={1} gutterBottom variant="h6" component="div">
                            Додаткова інформація:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Typography variant="body2">• Обмін <b>Укр поштою</b> можливий лише після попередження
                                продавця та для замовлень що були надіслані <b>Укр поштою</b>;</Typography>
                            <Typography variant="body2">• Надсилання товару <b>Без звернення</b> до продавця, а також
                                без заяви у відправленні, згідно Закону України <b>Не вважається
                                    обміном</b>;</Typography>
                            <Typography variant="body2">• У випадках коли <b>звернутися до продавця не вдається</b> з
                                технічних причин та будь яких інших, зв'язатися можливо також у коментарах до товарів,
                                за електронною поштою admin@yellowshop.in.ua та у формі нижче.</Typography>
                            <Typography mt={1} variant="body2"><b>Текст виділений червоним кольором можливо натиснути для відкриття сторінки чи виклику.</b></Typography>
                        </Typography>
                    </Box>
                </Paper>
                <ContactForm/>
            </Box>
        </div>
    )
}