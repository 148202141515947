import React, {useMemo, useState} from 'react';
import {Paper, Typography} from "@mui/material";
import Timer from "../../../../components/Timer";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import BuyButton from "../../../../components/buttons/BuyButton";
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import {useNavigate} from "react-router";
import {addCredentials} from "../../../../redux_toolkit/slices/credentialsSlice";
import {useDispatch} from "react-redux";
import {RandomIntFromInterval} from "../../../../utils/Random";
import ReactGA from 'react-ga4';
import {useSearchParams} from "react-router-dom";
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import {MaskPhoneAdapter} from "../../../../components/MaskPhoneAdapter";
import {formatPhoneNumber} from "../../../../utils/Utils";

function OrderBlock(props) {

    const dispatch = useDispatch()
    const [userName, setUserName] = useState('' )
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isCreated, setCreated] = useState(false)
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const utm_campaing = searchParams.get('utm_campaing');

    const currentDomain = window.location.hostname;

    const time = useMemo(() => {
        return Date.now() + RandomIntFromInterval(9990000,49990000);
    }, []);

    const buyBroduct = () => {
        ReactGA.event({
            category: 'Product Buy',
            action: `Click Buy Button product ${props.product.productId}`
        });

        if(phoneNumber.length !== 0 && userName.length !== 0 && !isCreated) {

            setCreated(true)

            let campaing = utm_campaing ? utm_campaing : 'no-campaing'

            dispatch(addCredentials({buyerName: userName, buyerPhone: phoneNumber, productId: props.product.productId}))

            fetch(`https://${currentDomain}/form-ok.php?name=${userName}&phone=${formatPhoneNumber(phoneNumber)}&product=${props.product.productId}&utm_campaing=` + campaing)
                .finally(() => {
                    setCreated(false)
                    navigate('/order')
                    window.scrollTo(0, 0)
                })
        }
    }

    return (
        <Paper sx={{
            width: '100%',
            backgroundColor: "#f39f86",
            backgroundImage: 'linear-gradient(315deg, #0f1c3e 0%, #353c52 74%)'
        }}>
                <Typography
                    align="center"
                    variant={'h4'}
                    sx={{
                        color: 'white',
                        textShadow: '#000000 1px 0 3px',
                        fontWeight: 'medium',
                        paddingTop: '10px'
                    }}
                    >
                    {props.product.hasSale ? "ПРОПОЗИЦІЯ ДІЄ" : "ЗАМОВИТИ ЗАРАЗ"}
                </Typography>

            {props.product.hasSale && <Timer time={time}/>}
                <div style={{
                    display: 'flex',
                    padding:'10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection:'column',
                    mb: 2}}
                >
                    <FormControl sx={{width: '90%'}}>
                        <Input
                            size="lg"
                            value={userName}
                            onChange={(event) => setUserName(event.target.value)}
                            placeholder="Прізвище Ім'я По батькові"
                            startDecorator={<PersonRoundedIcon/>}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius:'10px'
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{width: '90%'}}>
                        <Input
                            size="lg"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            placeholder="Номер телефону"
                            startDecorator={<PermPhoneMsgIcon/>}
                            slotProps={{ input: { component: MaskPhoneAdapter } }}
                            sx={{
                                    mt:2,
                                    backgroundColor: 'white',
                                    borderRadius:'10px',
                            }}
                        />
                    </FormControl>

                    <BuyButton
                               onClick={buyBroduct}
                               style={{fontSize:'1.8em', marginTop: '20px',marginBottom: '10px'}}
                               text={isCreated? 'Зачекайте...' :'Замовити'}/>


                    <div style={{display:"flex", flexDirection: "column"}}>
                        <Checkbox checked
                                  label="Погоджуюся з умовами оферти"
                                  size="sm"
                                  sx={{
                                      "& .MuiCheckbox-checkbox":{
                                          backgroundColor: '#ececec',
                                          border: "1px solid black",
                                          color: 'black'
                                      },
                                      "& .MuiCheckbox-label":{
                                          color: "white",
                                      },
                                      mt: '10px',
                                      mb: '10px'
                                  }}
                        />
                    </div>
                </div>

        </Paper>

    );
}

export default OrderBlock;