import React from 'react';

import {Route, Routes} from "react-router";
import Home from "../pages/home/Home";
import ProductsList from "../pages/productList/ProductsList";
import Refund from "../pages/footer/Refund";
import Exchange from "../pages/footer/Exchange";
import ContactsPage from "../pages/footer/ContactsPage";
import MapMarker from "../pages/footer/MapMarker";
import PayAndDelyvery from "../pages/footer/PayAndDelyvery";
import ScheduleWork from "../pages/footer/ScheduleWork";
import Product from "../pages/product/Product";
import Cart from "../pages/cart/Cart";
import ThanksForTheOrder from "../pages/cart/ThanksForTheOrder";
import PrivacyPolicy from "../pages/footer/PrivacyPolicy";
import AboutCompany from "../pages/footer/AboutCompany";
import PublicOffer from "../pages/footer/PublicOffer";

function MainRouter() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<ProductsList category={'all'}/>} />
            <Route path="/product" element={<Product/>} />
            <Route path="/products/hairs" element={<ProductsList category={'Волосся'}/>} />Аксесуари
            <Route path="/products/cars-products" element={<ProductsList category={'Автотовари'}/>} />
            <Route path="/products/accessories" element={<ProductsList category={'Аксесуари'}/>} />
            <Route path="/products/tools" element={<ProductsList category={'Інструменти'}/>} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/exchange" element={<Exchange />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/location" element={<MapMarker />} />
            <Route path="/payanddelyvery" element={<PayAndDelyvery />} />
            <Route path="/about-company" element={<AboutCompany />} />
            <Route path="/schedulework" element={<ScheduleWork />} />
            <Route path="/order" element={<ThanksForTheOrder />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/public-offer" element={<PublicOffer />} />
            <Route path="*" element={<div>Сторінка не знайдена</div>} />
        </Routes>
    );
}

export default MainRouter;