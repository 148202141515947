import * as React from 'react';
import {styled} from "@mui/material/styles";
import Button from '@mui/joy/Button';
import SendIcon from '@mui/icons-material/Send';

const StyledButton = styled(Button)(({theme, color = 'primary'}) => ({
        color: 'white',
        ':hover': {
            color: 'white',
            backgroundColor: '#b60f0f',
        },
        '&:disabled': {
            color: 'black',
            backgroundColor: '#ffffff',
        }

    })
);

export default function SendButton({icon, text, onClick, disabled, style, loading,loadingIndicator}) {

    return <StyledButton loading={loading}
                         style={style ? style : {}}
                         disabled={disabled === undefined ? false : disabled}
                         onClick={onClick}
                         variant="solid"
                         endDecorator={icon ? icon : <SendIcon/>}
                         sx={{mt: 1, backgroundColor: '#f15d14'}}>
                         {loading ? loadingIndicator : text}
            </StyledButton>;
}

