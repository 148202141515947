import * as React from 'react';

import BuyButton from "../buttons/BuyButton";
import LazyLoad from 'parm-react-lazyload';
import {Link, useSearchParams} from "react-router-dom";
import PriceProduct from "../PriceProduct";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AspectRatio from '@mui/joy/AspectRatio';
import Tooltip from '@mui/material/Tooltip';
import Card from "@mui/material/Card";


export default function ProductCard({price, name, images, hasSale, productId}) {

    const imageToShow = images.sort((a, b) => {return a.priority - b.priority;})[0];

    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `&utm_campaing=${utm_campaign}` : ``;

    return (
        <LazyLoad resize key={productId} height={256} offset={0} once>
            <Card key={productId} sx={{ backgroundColor: 'white', padding: '0px'}}>
                <Link key={productId} to={`../product?id=${productId}${hasUtmCampaign}`}>
                    <AspectRatio ratio="1/1" minHeight="120px" maxHeight="380px"
                                 onClick={() => window.scrollTo(0, 0)}
                    >
                        <img style={{
                            borderBottomLeftRadius: '0!important',
                            borderBottomRightRadius: '0!important',
                            objectFit: 'inherit',
                        }}
                             src={imageToShow.link}
                             loading="lazy"
                             alt=""
                        />
                    </AspectRatio>
                </Link>
                <Tooltip title={name ?? 'Без назви'}>
                    <Typography
                        sx={{
                            ml: '.5em',
                            mt: '.5em',
                            mb: 0.5,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {name ?? 'Без назви'}
                    </Typography>
                </Tooltip>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    mr: '1em',
                    ml: '.5em',
                    mb: '.5em'
                }}>
                    <PriceProduct price={price} hasSale={hasSale}/>

                    <Link key={productId} to={`../product?id=${productId}${hasUtmCampaign}`}>
                        <BuyButton text={"Переглянути"} onClick={() => window.scrollTo(0, 0)}/>
                    </Link>
                </Box>
            </Card>
        </LazyLoad>
    )
}


