import React, { useState } from 'react'

const YoutubePlayer = (props) => {
    const { videoId } = props
    const [showVideo, setShowVideo] = useState(false)

    return (
        <>
            {showVideo ? (
                <iframe
                    allow="autoplay"
                    width={'100%'}
                    height={`100%`}

                    src={`https://www.youtube.com/embed/${videoId}`}
                    style={{border:0}}
                    allowFullScreen
                ></iframe>
            ) : (
                <div
                    style={{
                        backgroundImage: `url('https://i.ytimg.com/vi_webp/${videoId}/hqdefault.webp')`,
                        backgroundSize: `140% 140%`,
                        width: '100%',
                        height: `100%`,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#000',
                        cursor: 'pointer',
                        borderRadius: '4px'
                    }}
                    onClick={() => setShowVideo(true)}
                >
                    <svg style={{
                        height: '20%',
                        width: '20%',
                    }}
                         version="1.1"
                         viewBox="0 0 68 48">
                        <path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fillOpacity="0.8"></path>
                        <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                    </svg>
                </div>
            )}
        </>
    )
}

export default YoutubePlayer