import React from 'react';
import InputComment from "./inputComment";
import CommentList from "./commentList";
import {Paper, Typography} from "@mui/material";

const CommentsBlock = (props) => {
    return (
        <Paper style={{marginTop: '20px', padding: '20px 0 20px 20px',backgroundImage: 'linear-gradient(315deg, #0f1c3e 0%, #353c52 74%)'}}>
            <div style={{margin: '0 auto', maxWidth: '800px'}}>
                <Typography sx={{color:'white'}} variant={'h5'}>
                    Коментарі:
                </Typography>
                <CommentList product={props.product}/>
                <Typography style={{margin: '0 auto',marginTop:'1em', width: '80%', color: 'white'}} variant={'h5'}>
                    Додати коментар:
                </Typography>
                <InputComment product={props.product}/>
            </div>
        </Paper>
    );
};

export default CommentsBlock;