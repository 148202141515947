//Сторінка Зв'язку
import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import ContactsPoster from '../../assets/images/poster/contacts.webp';
import Box from "@mui/material/Box";

export default function ContactsPage() {
    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                <Grid container justifyItems="center" justifyContent="center" xs={12} mt={1} mb={2}>
                    <Paper elevation={3} style={{padding: 0}}>
                        <img src={ContactsPoster} alt="Контакти" style={{height: 240,maxWidth: '100%', width: '100%'}}/>
                        <Box sx={{p: 2}}>
                            <Typography variant="h6" component="div">
                                Телефони для замовлень:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Typography variant="body2">
                                    • Київстар: ⠀
                                    <a style={{ color: '#ef4747'}} href="tel:0673576518">
                                        <b>📞 067-101-17-79</b>
                                    </a>
                                </Typography>
                                <Typography variant="body2">
                                    • Vodafone:⠀
                                    <a style={{ color: '#ef4747'}} href="tel:0660006887">
                                        <b>📞 066-000-68-87</b>
                                    </a>
                                </Typography>
                                <Typography variant="body2">
                                    • Lifecell: ⠀⠀
                                    <a style={{ color: '#ef4747'}} href="tel:0635296760">
                                        <b>📞 063-529-67-60</b>
                                    </a>
                                </Typography>
                            </Typography>
                            <Typography mt={2} variant="h6" component="div">
                                Телефон підтримки з обміну та повернення:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Typography variant="body2">
                                    • Київстар:{' '}
                                    <a style={{ color: '#ef4747'}} href="tel:0966946407">
                                        <b>📞 096-694-6407</b>
                                    </a>
                                </Typography>
                            </Typography>
                            <Typography mt={2} variant="h6" component="div">
                                Месенджери:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Typography variant="body2">
                                    • Viber:{' '}
                                    <a style={{ color: '#ef4747'}} href="viber://chat?number=%2B380966822934">
                                        <b>✏️ 380966822934 - лише текст</b>
                                    </a>{' '}
                                </Typography>
                            </Typography>
                            <Typography mt={2} variant="body2"><b>Текст виділений червоним кольором можливо натиснути для відкриття сторінки чи виклику.</b></Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}