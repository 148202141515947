import React from "react";
import {IMaskInput} from "react-imask";
import PropTypes from "prop-types";

export const MaskPhoneAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+38 (000) 000-00-00"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { value } })}
            overwrite
        />
    );
});

MaskPhoneAdapter.propTypes = {
    onChange: PropTypes.func.isRequired,
};