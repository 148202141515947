//Сторінка Політики конфіденційності
import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import PrivacyPoster from '../../assets/images/poster/privacy-policy.webp';
import Box from "@mui/material/Box";

export default function PrivacyPolicy() {
    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                <Grid container justifyItems="center" justifyContent="center" xs={12} mt={1} mb={2}>
                    <Paper elevation={3} style={{padding: 0}}>
                        <img src={PrivacyPoster} alt="Політика конфіденційності" style={{height: 240,maxWidth: '100%', width: '100%'}}/>
                        <Box sx={{p: 2, maxWidth:'500px'}}>
                            <Typography variant="h6" mb={1} component="div">
                                Політика конфіденційності:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1. Збір та використання інформації:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Ми збираємо інформацію від відвідувачів нашого сайту тільки в тих випадках, коли це необхідно для обробки їх замовлень або надання певної послуги. Ми можемо збирати таку інформацію, як ім'я, прізвище, по батькові, адресу електронної пошти, номер телефону та адресу доставки товарів.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                2. Захист персональних даних:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Ми дотримуємось законів та стандартів, які забезпечують захист персональних даних наших клієнтів. Ми не передаємо, не продаємо та не обмінюємо ваших персональних даних третім особам без вашого згоди.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                3. Використання cookies:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Ми використовуємо cookies на нашому сайті для збору інформації про відвідувачів та покупців, що дозволяє нам поліпшувати роботу сайту та надавати кращий сервіс. Відвідувачі можуть змінювати налаштування браузера для відмови від використання cookies.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                4. Захист від хакерських атак:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Ми прикладаємо всі необхідні заходи для захисту нашого сайту від хакерських атак та несанкціонованого доступу до даних користувачів.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                5. Контактна інформація:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Якщо виникли будь-які запитання щодо нашої політики конфіденційності або захисту персональних даних, ви можете зв'язатися з нами за допомогою контактної форми на нашому сайті або зв'язавшись з нашою підтримкою за телефоном або електронною поштою.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                6. Права користувачів:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Користувачі мають право на доступ до своїх персональних даних, на виправлення неправильних даних, на обмеження обробки, на видалення даних та на перенесення даних. Якщо ви хочете скористатися своїми правами, зв'яжіться з нами за допомогою контактної форми на сайті або зв'яжіться з нашою підтримкою за телефоном або електронною поштою.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                7. Зміни у політиці конфіденційності:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Ми можемо змінювати цю політику конфіденційності з часом, оскільки ми постійно стежимо за законодавчими змінами та рекомендаціями щодо захисту персональних даних. Ми будемо повідомляти вас про будь-які зміни, опублікувавши оновлену політику конфіденційності на нашому сайті.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                8. Прийняття умов:
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Користувачі нашого сайту підтверджують своє прийняття політики конфіденційності та зобов'язуються дотримуватися умов, викладених в цій політиці.
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mt={2}>
                                Ця політика конфіденційності є дійсною для всіх користувачів нашого інтернет-магазину Yellow Shop, що діють в Україні. Ми рекомендуємо користувачам ознайомитися з цією політикою та звернутися до нас з будь-якими запитаннями або зауваженнями.
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}