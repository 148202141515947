import React from 'react';
import {Typography} from "@mui/material";
import FormOK from "../../components/form-ok/FormOK";
import ReactFbq from "react-fbq";

function ThanksForTheOrder(props) {
    ReactFbq.track({ title: 'Lead', data: [] });
    return (
        <FormOK/>
    );
}

export default ThanksForTheOrder;