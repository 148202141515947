import React from 'react';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

function DescriptionProduct({description}) {
    return (
        <div style={{paddingLeft: '10px', margin: '20px'}}>
            <Markdown remarkPlugins={[remarkGfm]}>{description}</Markdown>
        </div>

    );
}

export default DescriptionProduct;