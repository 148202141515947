import * as React from 'react';
import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
        ':hover': {
            color: 'white',
            backgroundColor: '#b60f0f',
        },

    })
);

export default function BuyButton({icon, text, onClick, disabled, style}){
    return <StyledButton style={style? style:{}} disabled = {disabled === undefined? false : disabled} onClick={onClick} variant="contained" startIcon={icon? icon : <ShoppingCartIcon />}  sx={{backgroundColor:'#f15d14'}}>{text? text : "Придбати"}</StyledButton>;
}

