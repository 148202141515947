//Сторінка Про Компанію
import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import AboutPoster from '../../assets/images/poster/about-company.webp';
import Box from "@mui/material/Box";

export default function AboutCompany() {
    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                <Grid container justifyItems="center" justifyContent="center" xs={12} mt={1} mb={2}>
                    <Paper elevation={3} style={{padding: 0}}>
                        <img src={AboutPoster} alt="Контакти" style={{height: 190,maxWidth: '100%', width: '100%'}}/>
                        <Box sx={{p: 2,maxWidth:'600px'}}>
                            <Typography mb={1} variant="h6" component="div">
                                Про інтернет магазин Yellow Shop:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Typography mb={1} variant="body2">
                                    Yellow Shop - це кращий інтернет-магазин, де поєднується вміння вибрати найактуальніші товари з усіх куточків світу з неперевершеним досвідом обслуговування клієнтів. Наша компанія пропонує широкий асортимент товарів від моди до технологій, від косметики до предметів для дому, щоб задовольнити ваші усі бажання.
                                </Typography>
                                <Typography mb={1} variant="body2">
                                    Що робить Yellow Shop унікальним? По-перше, ми пильно стежимо за останніми тенденціями і пропонуємо найсвіжіші колекції, щоб ви завжди виглядали модно та стильно. Наші продукти відомі своєю вишуканістю і якістю, що гарантує, що ви отримаєте лише найкраще.
                                </Typography>
                                <Typography mb={1} variant="body2">
                                    По-друге, ми приділяємо надзвичайну увагу задоволенню наших клієнтів. Наша команда професіоналів готова надати вам персоналізовану консультацію та підтримку на кожному кроці вашого шопінгу. Ми завжди стежимо за вашими потребами і забезпечуємо бездоганне обслуговування.
                                </Typography>
                                <Typography mb={1} variant="body2">
                                    Крім того, ми прагнемо досягти найвищих стандартів безпеки та конфіденційності. Ваша приватність - наш пріоритет, і ми зобов'язуємося забезпечити безпечне та надійне середовище для вашого шопінгу.
                                </Typography>
                                <Typography mb={1} variant="body2">
                                    Нехай Yellow Shop стане вашим надійним партнером у всьому, що стосується онлайн шопінгу. Приєднуйтесь.
                                </Typography>
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}