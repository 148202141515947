import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Container} from "@mui/material";
import Comment from "./comment";
import {useSearchParams} from "react-router-dom";

const CommentList = (props) => {
    const commentsList = useSelector(state => state.comments.comments)
    const [commentsProductRedux, setCommentsProductRedux] = useState([])
    const [commentsProductJSON, setCommentsProductJSON] = useState([])
    const searchParams = useSearchParams()

    useEffect(()=>{
        const productId = String(Array.from(searchParams[0].entries()).filter(name => name[0] === 'id')[0][1])
        if (commentsList[productId] === undefined)
            setCommentsProductRedux([])
        else
            setCommentsProductRedux(commentsList[productId])

        let productJSON = props.product;
        const commentsProductJSON = productJSON?.comments ? productJSON?.comments : []
        setCommentsProductJSON(commentsProductJSON)
    }, [commentsList])
    return (
        <Container>
            {
                commentsProductJSON?.map((commentObject, id) => <Comment key={id}{...commentObject}></Comment>)
            }
            {
                commentsProductRedux?.map((commentObject, id) => <Comment key={id}{...commentObject}></Comment>)
            }
            {
                commentsProductJSON.length + commentsProductRedux.length === 0 &&
                [{userName: 'YellowShop', text: 'Нажаль, кометарів до цього товару поки-що немає.\nАле ви можете стати першим! 😁'}]
                    .map((commentObject, id) => <Comment key={id}{...commentObject}></Comment>)
            }

        </Container>
    );
};

export default CommentList;