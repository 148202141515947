import './App.css';

import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CssBaseline from '@mui/material/CssBaseline';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Footer from "./components/footer/Footer";
import Logo from "./components/icons/Logo";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {Slide} from "@mui/material";
import MainRouter from "./components/MainRouter";
import MainMenu from "./components/MainMenu";
import {useNavigate} from "react-router";
import CartIcon from "./pages/cart/cartIcon/CartIcon";
import {useSearchParams} from "react-router-dom";

function HideOnScroll(props) {

    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `0px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

function App() {
    const [mainPageMinWidth, setMainPageMinWidth] = useState(0);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const footer = useRef();
    const appBar = useRef();

    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setOpen(false);
    };
    useEffect(() => {
        const footerHeight = footer.current.getBoundingClientRect().height
        const appBarHeight = appBar.current.getBoundingClientRect().height
        const minHeightMainPage = `calc(100vh - ${appBarHeight || 0}px - ${footerHeight || 0}px)`
        setMainPageMinWidth(minHeightMainPage)

    }, [window.innerWidth, window.innerHeight])

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(231, 235, 240)'}}>
            <CssBaseline/>
            <HideOnScroll>
                <AppBar
                    position="sticky"
                    open={open}>

                    <Toolbar ref={appBar}
                             sx={{
                                 backgroundColor: "#f39f86",
                                 backgroundImage: "linear-gradient(315deg, #f39f86 0%, #f9d976 74%)"
                             }}>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{color: "#0f1c3e", mr: 2, ...(open && {display: 'none'})}}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Logo onClick={() => navigate(`/${hasUtmCampaign}`)} width="50" height="50" sx={{cursor: 'pointer'}}></Logo>
                        <Typography onClick={() => navigate(`/${hasUtmCampaign}`)} ml={1} mt={1} color='black' variant="h4" noWrap
                                    component="div" sx={{flexGrow: 1, cursor: 'pointer', fontFamily: 'Lobster, cursive'}}>
                            Yellow Shop
                        </Typography>
                        <CartIcon/>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <SwipeableDrawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                PaperProps={{
                    sx: {
                        backgroundImage: "linear-gradient(315deg, #0f1c3e 0%, #353c52 74%)",
                        color: "white",
                    }
                }}
                anchor="left"
                open={open}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{color: "#ffffff"}}/> :
                            <ChevronRightIcon sx={{color: "#ffffff"}}/>}
                    </IconButton>
                </DrawerHeader>
                <MainMenu handleDrawerClose={handleDrawerClose}/>
            </SwipeableDrawer>
            <Main style={{minHeight: `${mainPageMinWidth}`, padding: '1em', position: 'relative'}} open={open}>
                <MainRouter/>
            </Main>
            <div ref={footer}>
                <Footer/>
            </div>
        </Box>
    );
}

export default App;

