import Countdown, {zeroPad} from "react-countdown";

const Completion = () => <span>Акція закінчилась</span>;
const clockStyle = {
    color: 'white',
    textAlign: 'center',
    fontSize: '34pt',
    padding: '3px',
    height:'30px'
}

const countContainterStyle = {
    backgroundImage: 'radial-gradient(circle, #434343 20%, black 100%)'
}
const renderer = ({hours, minutes, seconds, completed}) => {

    if (completed) {
        return <Completion/>;
    } else {
        return (
            <div style={{width: '200px',  margin: '0 auto', marginTop: '10px'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={countContainterStyle}>
                        <span style={{...clockStyle}}>{zeroPad(hours)}</span>
                    </div>

                    <b style={{textShadow: '#000000 1px 0 3px', color: "white", fontSize: '32pt'}}>:</b>
                    <div style={countContainterStyle}>
                        <span style={clockStyle}>{zeroPad(minutes)}</span>
                    </div>
                    <b style={{textShadow: '#000000 1px 0 3px', color: "white", fontSize: '32pt'}}>:</b>
                    <div style={countContainterStyle}>
                        <span style={clockStyle}>{zeroPad(seconds)}</span>
                    </div>

                </div>
                <div style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                    <p style={{textShadow: '#000000 1px 0 3px'}}>Годин</p>
                    <p style={{textShadow: '#000000 1px 0 3px'}}>Хвилин</p>
                    <p style={{textShadow: '#000000 1px 0 3px'}}>Секунд</p>
                </div>
            </div>
        );
    }
};

export default function Timer(props) {
    return (
        <Countdown
            zeroPadTime={2}
            autoStart={true}
            renderer={renderer}
            date={props.time}
        />
    );
};