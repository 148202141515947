import {Typography} from '@mui/material';
import Container from "@mui/material/Container";
import styles from './footer.module.css'
import FooterLink from "./FooterLink";
import {useSearchParams} from "react-router-dom";

export default function Footer() {
    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    return (
        <div style={{
            backgroundColor: '#fce043',
            backgroundImage: 'linear-gradient(315deg, #0f1c3e 0%, #353c52 74%)',
            color: '#bdbcbc', width: '100%',
            display: 'block',
            margin: '0 auto',
            padding: '15px',
            fontSize: '12px',
            textDecoration: 'none'
        }}>
            <Container className={styles.footer} style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <Typography sx={{color: 'white', fontWeight:'bold'}} mb={2} mt={2} variant="h5">Обслуговування</Typography>
                    <FooterLink link={`/payanddelyvery${hasUtmCampaign}`} text={'Оплата та доставка'}/>
                    <FooterLink link={`/refund${hasUtmCampaign}`} text={'Повернення'}/>
                    <FooterLink link={`/exchange${hasUtmCampaign}`} text={'Обмін'}/>
                </div>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <Typography sx={{color: 'white', fontWeight:'bold'}} mb={2} mt={2} variant="h5">Про</Typography>
                    <FooterLink link={`/about-company${hasUtmCampaign}`} text={'Компанію'}/>
                    <FooterLink link={`/location${hasUtmCampaign}`} text={'Розташування'}/>
                    <FooterLink link={`/contacts${hasUtmCampaign}`} text={"Зв'язок"}/>
                    <FooterLink link={`/schedulework${hasUtmCampaign}`} text={'Графік роботи'}/>
                </div>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <Typography sx={{color: 'white', fontWeight:'bold'}} mb={2} mt={2} variant="h5">Корисне</Typography>
                    <FooterLink link={`/privacy-policy${hasUtmCampaign}`} text={'Політика конфіденційності'}/>
                    <FooterLink link={`/public-offer${hasUtmCampaign}`} text={'Правила та умови'}/>
                </div>
            </Container>
            <Typography mt={3} variant="h6">
                <span style={{display: "flex", justifyContent: "space-around", color: 'white'}}>YellowShop © Всі права захищені.</span>
            </Typography>
        </div>
    )
}