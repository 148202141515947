import React from 'react';
import {Typography} from "@mui/material";

function NameProduct({name}) {
    return (
        <Typography p={1}
                    color="white"
                    align="center"
                    sx={{
                        textShadow: '#000000 1px 0 3px',
                        fontWeight: 'medium'
                    }} variant={'h5'}>
            {name}</Typography>
    );
}

export default NameProduct;