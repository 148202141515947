import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    buyerName: '',
    buyerPhone: '',
    productId: ''
}

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        addCredentials: (state, action) => {
            state.buyerName = action.payload.buyerName
            state.buyerPhone = action.payload.buyerPhone
            state.productId = action.payload.productId
        }
    }
})

export const {
    addCredentials,
} = credentialsSlice.actions

export default credentialsSlice.reducer