import * as React from "react"

const AccessoriesIcon = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         {...props}
         fill="#ffffff"
         viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g>
            <g>
                <path d="M356.558,325.289c-6.855-22.522-26.373-39.666-50.938-44.741c-22.589-4.668-45.086,1.705-61.805,17.132
			c-18.469-17.039-43.923-22.969-68.406-15.44c-26.06,8.013-43.956,28.968-46.704,54.69c-2.767,25.926,7.977,78.046,111.042,135.846
			c1.264,0.709,2.667,1.063,4.07,1.063s2.806-0.354,4.07-1.063c18.241-10.229,34.572-20.881,48.541-31.662
			c3.638-2.807,4.311-8.033,1.503-11.672c-2.808-3.638-8.032-4.312-11.672-1.503c-12.288,9.483-26.552,18.895-42.444,28.013
			c-66.591-38.219-102.456-80.81-98.563-117.253c2.029-18.99,15.458-34.529,35.047-40.551c16.426-5.05,40.069-3.257,57.167,16.919
			c1.581,1.866,3.903,2.941,6.348,2.941s4.767-1.076,6.348-2.941c13.087-15.445,32.562-22.255,52.089-18.222
			c18.596,3.842,33.304,16.598,38.384,33.291c6.861,22.539-3.865,49.727-30.203,76.557c-3.219,3.28-3.17,8.548,0.11,11.767
			c3.281,3.22,8.548,3.17,11.767-0.11C340.149,400.179,368.317,363.924,356.558,325.289z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M108.779,37.007c-2.332-0.001-9.426-0.001-15.465-13.287c-3.641-8.011-4.739-16.355-4.746-16.422
			C88.052,3.13,84.509,0,80.31,0c-4.199,0-7.742,3.131-8.258,7.299c-0.037,0.297-4.127,29.707-20.21,29.707
			c-4.595,0-8.321,3.726-8.321,8.321s3.726,8.321,8.321,8.321c2.331,0,9.425,0,15.464,13.286c3.61,7.942,4.719,16.209,4.748,16.435
			c0.52,4.156,4.052,7.27,8.241,7.279c0.006,0,0.011,0,0.017,0c4.186,0,7.724-3.12,8.254-7.275
			c0.011-0.083,1.107-8.426,4.749-16.438c6.039-13.286,13.132-13.286,15.464-13.286c4.595,0,8.321-3.726,8.321-8.321
			S113.374,37.007,108.779,37.007z M80.31,55.771c-2.433-4.391-5.109-7.806-7.887-10.443c2.777-2.636,5.454-6.051,7.887-10.443
			c2.433,4.391,5.109,7.806,7.887,10.443C85.419,47.963,82.743,51.378,80.31,55.771z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M227.964,180.154c-2.332-0.001-9.426-0.001-15.465-13.286c-3.641-8.011-4.739-16.355-4.746-16.422
			c-0.516-4.168-4.058-7.299-8.258-7.299c-4.199,0-7.742,3.131-8.258,7.299c-0.037,0.297-4.127,29.707-20.21,29.707
			c-4.595,0-8.321,3.726-8.321,8.321s3.726,8.321,8.321,8.321c2.331,0,9.425,0,15.464,13.286c3.61,7.942,4.719,16.209,4.748,16.435
			c0.52,4.156,4.052,7.27,8.241,7.279c0.006,0,0.011,0,0.017,0c4.186,0,7.724-3.12,8.254-7.275
			c0.011-0.083,1.107-8.426,4.748-16.438c6.039-13.286,13.132-13.286,15.464-13.286c4.595,0,8.321-3.726,8.321-8.321
			S232.559,180.154,227.964,180.154z M199.495,198.917c-2.433-4.391-5.109-7.806-7.887-10.443c2.777-2.636,5.454-6.051,7.887-10.443
			c2.433,4.391,5.109,7.806,7.887,10.443C204.604,191.111,201.927,194.526,199.495,198.917z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M491.445,78.766c-2.331,0-9.425,0-15.464-13.286c-3.64-8.011-4.736-16.355-4.745-16.422
			c-0.516-4.162-4.048-7.283-8.243-7.29c-0.006,0-0.011,0-0.017,0c-4.186,0-7.724,3.12-8.254,7.275
			c-0.011,0.083-1.107,8.426-4.749,16.438c-6.039,13.286-13.132,13.286-15.464,13.286c-4.595,0-8.321,3.726-8.321,8.321
			s3.726,8.321,8.321,8.321c2.331,0,9.425,0,15.464,13.286c3.641,8.011,4.739,16.355,4.749,16.438
			c0.524,4.161,4.061,7.283,8.256,7.283c4.2,0,7.742-3.131,8.258-7.299c0.037-0.297,4.127-29.707,20.21-29.707
			c4.595,0,8.321-3.726,8.321-8.321S496.04,78.766,491.445,78.766z M462.978,97.53c-2.433-4.391-5.109-7.806-7.887-10.443
			c2.778-2.636,5.454-6.051,7.887-10.443c2.433,4.391,5.109,7.806,7.887,10.443C468.087,89.724,465.411,93.138,462.978,97.53z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M402.569,108.301c-42.876-28.75-99.256-44.584-158.753-44.584S127.939,79.551,85.062,108.301
			c-8.557,5.738-16.547,11.972-23.745,18.528c-3.398,3.094-3.643,8.356-0.549,11.755s8.358,3.643,11.755,0.549
			c6.594-6.004,13.93-11.727,21.808-17.01c40.166-26.931,93.254-41.764,149.486-41.764c56.232,0,109.32,14.832,149.485,41.765
			c42.208,28.301,65.454,66.6,65.454,107.841c0,41.241-23.245,79.54-65.454,107.841c-0.284,0.19-0.569,0.381-0.857,0.57
			c-0.001-0.018-0.003-0.036-0.004-0.054c-0.696-11.926-3.598-23.852-8.591-34.713c13.918-10.794,24.557-22.874,31.675-35.983
			c2.193-4.038,0.697-9.09-3.342-11.283c-4.04-2.193-9.091-0.697-11.283,3.342c-5.703,10.502-14.239,20.333-25.413,29.314
			c-18.374-26.443-49.653-43.22-84.358-43.22c-16.596,0-32.853,3.953-47.314,11.47c-14.461-7.517-30.717-11.47-47.314-11.47
			c-34.704,0-65.983,16.78-84.356,43.225C90.569,271.629,78.8,250.906,78.8,229.965c0-24.067,15.389-47.638,43.332-66.375
			c32.03-21.477,75.244-33.305,121.683-33.305c46.438,0,89.651,11.828,121.682,33.306c26.478,17.754,41.826,40.098,43.214,62.918
			c0.14,2.289,1.217,4.419,2.979,5.887c0.05,0.042,0.112,0.092,0.173,0.14c3.457,2.791,8.531,2.375,11.485-0.999
			c1.762-2.014,2.38-4.638,1.905-7.075c-2.084-27.747-19.975-54.235-50.487-74.692c-34.741-23.297-81.246-36.125-130.949-36.125
			s-96.209,12.829-130.95,36.125c-32.698,21.924-50.706,50.405-50.706,80.197c0,26.811,14.72,52.746,41.627,73.644
			c-2.292,4.987-4.172,10.213-5.592,15.645c-1.622,6.21-2.699,12.659-2.999,19.076c-0.001,0.017-0.003,0.034-0.004,0.051
			c-0.288-0.191-0.577-0.383-0.862-0.575c-42.208-28.301-65.454-66.6-65.454-107.841c0-23.835,7.575-46.467,22.517-67.267
			c2.682-3.732,1.828-8.931-1.904-11.613s-8.931-1.828-11.613,1.904c-17.015,23.687-25.643,49.585-25.643,76.976
			C12.235,276.932,38.1,320.14,85.064,351.63c3.488,2.339,7.167,4.648,10.976,6.892c2.307,16.513,8.888,35.496,23.006,56.065
			c21.226,30.922,56.359,60.267,104.421,87.219l16.28,9.13c1.264,0.709,2.667,1.063,4.07,1.063s2.806-0.354,4.07-1.063l16.28-9.13
			c48.064-26.953,83.198-56.299,104.425-87.219c14.122-20.572,20.704-39.556,23.01-56.07c3.808-2.243,7.484-4.551,10.968-6.888
			c46.964-31.49,72.829-74.698,72.829-121.664C475.398,182.998,449.533,139.791,402.569,108.301z M375.647,351.312
			c-1.288,15.307-6.854,33.581-20.773,53.857c-19.771,28.798-53.027,56.428-98.846,82.124l-12.21,6.848l-12.211-6.848
			c-45.816-25.694-79.071-53.324-98.84-82.124c-13.922-20.281-19.487-38.562-20.772-53.872c-0.25-2.97-0.339-5.951-0.272-8.926
			c0.296-12.973,3.601-25.829,9.945-37.224c14.406-25.863,42.866-42.725,74.834-42.725c15.498,0,30.008,3.874,43.126,11.515
			c2.588,1.507,5.788,1.507,8.376,0c13.118-7.641,27.627-11.515,43.126-11.515c31.996,0,60.477,16.888,74.873,42.786
			C373.765,319.167,376.979,335.433,375.647,351.312z"/>
            </g>
        </g>
    </svg>
)

export default AccessoriesIcon
