import * as React from "react"

const RefundIcon = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         {...props}
         fill="#ffffff"
         viewBox="0 0 300.219 300.219"
    >
        <g>
            <path d="M167.692,199.092v-5.919c14.336-3.32,25.067-16.33,25.067-31.84c0-15.51-10.731-28.519-25.067-31.84V94.691
		c7.42,2.628,14.194,8.361,16.705,10.965c2.86,2.987,7.602,3.096,10.596,0.239c2.997-2.86,3.107-7.607,0.248-10.604
		c-0.572-0.601-12.463-12.884-27.549-16.164v-5.895c0-4.143-3.357-7.5-7.5-7.5c-4.143,0-7.5,3.357-7.5,7.5v6.062
		c-14.031,3.544-24.46,16.408-24.46,31.697c0,15.29,10.429,28.155,24.46,31.7v34.22c-7.129-3.438-13.964-10.194-16.389-13.059
		c-2.665-3.172-7.397-3.582-10.566-0.918c-3.172,2.664-3.583,7.395-0.918,10.566c1.501,1.787,13.318,15.394,27.873,19.393v6.198
		c0,4.143,3.357,7.5,7.5,7.5C164.334,206.592,167.692,203.234,167.692,199.092z M177.759,161.333c0,7.112-4.136,13.241-10.067,16.04
		v-32.08C173.623,148.092,177.759,154.221,177.759,161.333z M143.232,110.992c0-6.866,3.855-12.816,9.46-15.739v31.481
		C147.087,123.811,143.232,117.86,143.232,110.992z"/>
            <path d="M145.915,271.567c0.262,0.027,0.521,0.041,0.781,0.041c1.845,0,3.636-0.681,5.02-1.928c1.579-1.422,2.48-3.447,2.48-5.572
		v-43.277c0-3.689-2.683-6.83-6.327-7.407c-33.438-5.297-60.156-32.448-64.974-66.029c-0.529-3.692-3.693-6.434-7.424-6.434H32.367
		c-2.112,0-4.126,0.891-5.548,2.453c-1.421,1.563-2.118,3.651-1.919,5.754C30.983,213.388,81.877,264.864,145.915,271.567z
		 M69.199,155.96c3.709,17.262,12.175,32.947,24.715,45.678c12.547,12.737,28.097,21.432,45.283,25.376v28.477
		c-50.326-8.841-89.997-49.002-98.301-99.53H69.199z"/>
            <path d="M36.295,221.182c-2.124-3.556-6.729-4.715-10.284-2.593c-3.557,2.123-4.717,6.729-2.593,10.284
		c0.184,0.309,0.369,0.616,0.557,0.923c1.415,2.313,3.88,3.588,6.405,3.588c1.334,0,2.686-0.355,3.906-1.103
		c3.534-2.162,4.646-6.778,2.485-10.312L36.295,221.182z"/>
            <path d="M12.479,178.927c4.12-0.425,7.115-4.11,6.69-8.23l-0.092-0.928c-0.388-4.124-4.039-7.146-8.17-6.764
		c-4.124,0.388-7.152,4.046-6.764,8.17l0.105,1.063c0.398,3.858,3.654,6.73,7.451,6.73
		C11.956,178.968,12.217,178.954,12.479,178.927z"/>
            <path d="M25.111,197.247l-0.303-0.888c-1.324-3.923-5.58-6.032-9.505-4.708s-6.032,5.58-4.708,9.505l0.344,1.005
		c1.075,3.101,3.979,5.045,7.086,5.045c0.814,0,1.644-0.134,2.457-0.416C24.396,205.434,26.468,201.16,25.111,197.247z"/>
            <path d="M151.139,285.219l-0.435-0.001c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5l0.317,0.001c0.02,0,0.04,0,0.06,0
		c4.115,0,7.467-3.319,7.499-7.441C158.613,288.635,155.281,285.251,151.139,285.219z"/>
            <path d="M124.309,282.725l-0.952-0.186c-4.064-0.811-8.009,1.84-8.812,5.903c-0.804,4.063,1.84,8.009,5.903,8.811l1.011,0.197
		c0.481,0.094,0.96,0.138,1.433,0.138c3.521,0,6.662-2.491,7.355-6.076C131.034,287.445,128.375,283.511,124.309,282.725z"/>
            <path d="M98.07,274.847l-0.876-0.371c-3.809-1.622-8.216,0.146-9.842,3.955c-1.626,3.81,0.145,8.216,3.955,9.842l0.966,0.408
		c0.947,0.397,1.929,0.585,2.896,0.585c2.932,0,5.716-1.73,6.92-4.603C103.691,280.842,101.891,276.447,98.07,274.847z"/>
            <path d="M52.714,243.091c-2.817-3.036-7.563-3.213-10.599-0.396c-3.036,2.817-3.214,7.563-0.396,10.599l0.702,0.754
		c1.477,1.578,3.475,2.376,5.479,2.376c1.836,0,3.676-0.67,5.122-2.023c3.025-2.83,3.183-7.576,0.353-10.601L52.714,243.091z"/>
            <path d="M74.074,261.678l-0.765-0.535c-3.385-2.39-8.064-1.583-10.453,1.801c-2.389,3.384-1.583,8.064,1.801,10.453l0.874,0.611
		c1.303,0.902,2.791,1.336,4.265,1.336c2.377,0,4.716-1.128,6.172-3.23C78.325,268.709,77.478,264.037,74.074,261.678z"/>
            <path d="M160.192,0c-34.519,0-67.426,12.981-92.66,36.553c-25.1,23.446-40.325,55.176-42.873,89.346
		c-0.155,2.08,0.563,4.131,1.983,5.66c1.419,1.528,3.411,2.398,5.497,2.398h43.007c3.817,0,7.026-2.868,7.453-6.661
		c4.451-39.566,37.81-69.402,77.594-69.402c43.068,0,78.106,35.111,78.106,78.269c0,39.218-29.235,72.574-68.004,77.588
		c-3.739,0.484-6.538,3.668-6.538,7.439v43.151c0,2.101,0.881,4.104,2.428,5.524c1.39,1.275,3.201,1.976,5.072,1.976
		c0.212,0,0.426-0.009,0.64-0.027c33.794-2.892,65.119-18.286,88.205-43.345c23.221-25.206,36.009-57.987,36.009-92.306
		C296.11,61.082,235.137,0,160.192,0z M178.756,255.886v-28.344c42.933-8.706,74.542-46.877,74.542-91.381
		c0-51.429-41.768-93.269-93.106-93.269c-45.167,0-83.386,32.255-91.51,76.063H40.491C48.89,60.042,99.978,15,160.192,15
		c66.675,0,120.918,54.353,120.918,121.161C281.11,195.782,236.772,246.853,178.756,255.886z"/>
        </g>
    </svg>
)

export default RefundIcon
