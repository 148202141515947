import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Paper, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {editCount} from "../../redux_toolkit/slices/cartSlice";
import BuyButton from "../../components/buttons/BuyButton";
import PopupOrder from "./popupOrder";

const CartItem = ({index, name, price, count, id, thumb, characteristic}) => {
    const dispatch = useDispatch()
 return(
         <Paper key={index} sx={{padding: '10px', margin: '10px', width:'100%'}}>
             <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                 <Typography sx={{display:'inline'}}>
                     {name}:{characteristic} | {(parseFloat(price)*count).toFixed(2)}грн
                 </Typography>
                 <img src={thumb} style={{ marginLeft:'20px',height:'50px', display: "inline"}}/>
             </div>
             <IconButton style={{border:'1px solid gray', width: '20px', height:'20px', borderRadius: '0', padding:'0 0 4px 0'}}
                         onClick={() => dispatch(editCount({
                             index: index,
                             characteristic: characteristic,
                             count: -1}))}
                         color={"secondary"}>-</IconButton>
             <span> {count}шт. </span>
             <IconButton style={{border:'1px solid gray', width: '20px', height:'20px', borderRadius: '0', padding:'1px 0 0 0'}}
                         onClick={() => dispatch(editCount({
                             index: index,
                             characteristic: characteristic,
                             count: 1}))}
                         color={"success"}>+</IconButton>
         </Paper>
 )
}

function Cart() {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.products.products)
    const [isPopupOpen, setPopupOpen] = useState(false)

    if(cart.length === 0)
        return (
            <div style={{display:'flex', margin:'0 auto', flexDirection:'column', alignItems: 'center', justifyContent:'center',
                minWidth: 'min(100%, 600px)', boxSizing:'border-box',maxWidth:'700px'}}>
                <Alert severity="success">
                    <Typography style={{margin: '0 auto',wordWrap: 'break-word'}} variant={"h6"}>
                        У кошику пусто, але це легко виправити :)
                    </Typography>
                </Alert>
            </div>
        )

    return (
        <div style={{width: '100%', }}>
            <PopupOrder order={cart} isOpen={isPopupOpen} setOpen={setPopupOpen} />

            <div style={{display:'flex', margin:'0 auto', flexDirection:'column', alignItems: 'center', justifyContent:'center',
                minWidth: 'min(100%, 600px)', boxSizing:'border-box',maxWidth:'700px'}}>
                {cart.map((product, id) => <CartItem key={id} index={id} {...product} />)}

            </div>
            <div style={{
                margin: '0 auto', width: 'min(100%, 600px)',
            }}>

                <Typography>
                    Усього: {cart.reduce((accum, prod)=> accum + parseFloat(prod.price) * parseInt(prod.count), 0)}грн
                </Typography>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <BuyButton onClick={() =>
                        setPopupOpen(true)} text={'Оформити замовлення'}
                               style={{margin: '10px', padding:'10px'}}
                    />
                    {/*<Button color={"info"} variant={"outlined"} onClick={()=>dispatch(clearCart())} >*/}
                    {/*    <Typography>Очистити кошик</Typography>*/}
                    {/*    <Delete size={'large'}/>*/}
                    {/*</Button>*/}
                </div>
            </div>

        </div>
    );
}

export default Cart;