import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {Container} from "@mui/material";
import {addComment,} from "../../redux_toolkit/slices/commentsSlice";
import {useSearchParams} from "react-router-dom";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import Textarea from '@mui/joy/Textarea';
import SendButton from "../buttons/SendButton";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import {MaskPhoneAdapter} from "../MaskPhoneAdapter";

const InputComment = (props) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [inputUserName, setInputUserName] = useState('')
    const [inputComment, setInputComment] = useState('')
    const [inputPhoneNumber, setInputPhoneNumber] = useState('')
    const searchParams = useSearchParams()

    function addFakeComment(){
        setLoading(true)
        setInputUserName("")
        setInputPhoneNumber("")
        setInputComment("")
        const productId = Array.from(searchParams[0].entries()).filter(name => name[0] === 'id')[0][1]
        const productName = props.product.name

        setTimeout(()=>{
            setLoading(false)
            dispatch(addComment({id: productId, userName: inputUserName, text: inputComment}))
            fetch(`https://yellowshop.in.ua/comments.php?name=${inputUserName}&phone=${inputPhoneNumber}&product=${productName}&comment=${inputComment}`)
        },1500)

    }

    return (
        <Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <FormControl sx={{width: '100%', maxWidth:'500px'}}>
                <Input
                    size="lg"
                    value={inputUserName}
                    onChange={(event) => setInputUserName(event.target.value)}
                    placeholder="Прізвище та Ім'я"
                    startDecorator={<PersonRoundedIcon/>}
                    sx={{
                        marginTop: '1em',
                        backgroundColor: 'white',
                        borderRadius:'10px',
                        input: { color: 'black',opacity: 1 }
                }}
                />
            </FormControl>
            <FormControl sx={{width: '100%', maxWidth:'500px'}}>
                <Input
                    size="lg"
                    value={inputPhoneNumber}
                    onChange={(event) => setInputPhoneNumber(event.target.value)}
                    placeholder="Номер телефону"
                    startDecorator={<PermPhoneMsgIcon/>}
                    slotProps={{ input: { component: MaskPhoneAdapter } }}
                    sx={{
                        color:'black',
                        marginTop: '1em',
                        mt:1,backgroundColor: 'white',
                        borderRadius:'10px'
                    }}
                />
            </FormControl>

            <Textarea
                style={{margin: '0 auto',marginTop:'1em', width: '100%', maxWidth:'500px'}}
                placeholder="Ваш коментар"
                variant="solid"
                value={inputComment}
                minRows={6}
                onInput={(e)=> setInputComment(e.target.value)}
                sx={{bgcolor:'white',color:'black',width: 'min(100%, 400px)', marginTop: '1em'}}
            />

            <SendButton
                style={{margin: '0 auto',marginTop:'1em', width: '100%', maxWidth:'500px'}}
                onClick={addFakeComment}
                loading={isLoading}
                loadingIndicator="Додається коментарій..."
                disabled={!(inputUserName && inputPhoneNumber && inputComment)}
                text={'Додати коментар'}
            />

        </Container>
    );
};

export default InputComment;