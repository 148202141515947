import * as React from "react"

const Logo = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         {...props}
         viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
            <path d="M466 4869 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
            l-32 -67 0 -2016 0 -2015 37 -76 c45 -91 103 -147 196 -191 l67 -32 2223 -3
            2223 -2 24 24 c14 14 25 33 25 43 0 10 -54 629 -120 1377 -66 747 -120 1365
            -120 1373 0 8 -11 25 -25 38 -24 25 -24 25 -240 25 l-215 0 0 143 c0 178 -11
            240 -61 344 -104 216 -339 340 -580 305 -181 -26 -340 -143 -418 -305 -50
            -104 -61 -166 -61 -344 l0 -143 -120 0 -119 0 -3 728 -3 728 -37 76 c-45 91
            -103 147 -196 191 l-67 32 -1035 2 c-604 1 -1051 -3 -1074 -8z m2088 -161 c45
            -13 146 -105 146 -132 0 -14 -116 -16 -1180 -16 -1064 0 -1180 2 -1180 16 0
            24 101 119 140 131 53 16 2018 17 2074 1z m166 -2148 l0 -1840 -1200 0 -1200
            0 0 1840 0 1840 1200 0 1200 0 0 -1840z m1124 1162 c91 -42 149 -100 194 -190
            l37 -76 4 -168 3 -168 -402 0 -402 0 4 168 c3 163 4 168 35 234 40 85 97 147
            172 186 78 42 122 52 213 48 61 -3 92 -11 142 -34z m-724 -937 c0 -173 0 -176
            25 -200 15 -16 36 -25 55 -25 19 0 40 9 55 25 25 24 25 27 25 200 l0 175 400
            0 400 0 0 -175 c0 -173 0 -176 25 -200 15 -16 36 -25 55 -25 19 0 40 9 55 25
            25 24 25 27 25 200 l0 175 165 0 c155 0 165 -1 165 -19 0 -10 50 -579 110
            -1264 61 -685 110 -1253 110 -1261 0 -14 -97 -16 -993 -16 l-993 0 35 72 36
            72 3 1208 2 1208 120 0 120 0 0 -175z m-420 -2241 c0 -27 -101 -119 -146 -132
            -60 -17 -2008 -17 -2068 0 -45 13 -146 105 -146 132 0 14 116 16 1180 16 1064
            0 1180 -2 1180 -16z"/>
            <path d="M1245 3748 c-27 -5 -86 -27 -131 -48 -163 -79 -280 -238 -306 -419
            l-12 -81 -133 0 c-129 0 -135 -1 -158 -25 -14 -13 -25 -32 -25 -42 0 -10 38
            -207 84 -438 73 -364 87 -422 106 -437 21 -17 63 -18 692 -18 l669 0 24 -25
            c33 -32 33 -78 0 -110 l-24 -25 -672 0 c-498 0 -682 -3 -713 -12 -86 -26 -166
            -136 -166 -228 0 -92 80 -202 166 -228 31 -9 232 -12 793 -12 l752 0 24 25
            c16 15 25 36 25 55 0 19 -9 40 -25 55 -20 21 -34 25 -84 25 l-60 0 6 54 c3 30
            1 68 -5 84 -10 28 -9 31 18 40 46 16 106 78 130 134 40 94 12 200 -71 272 -24
            20 -46 36 -50 36 -6 0 136 719 178 895 l19 80 113 5 c103 5 114 7 132 29 26
            32 24 77 -6 106 -23 24 -28 25 -173 25 -129 0 -153 -3 -172 -18 -17 -14 -29
            -50 -54 -157 l-32 -140 -90 -3 -90 -3 -12 82 c-46 316 -347 527 -667 467z
            m279 -186 c91 -42 149 -100 194 -190 35 -72 37 -81 37 -172 0 -91 -2 -100 -37
            -172 -45 -91 -103 -147 -196 -191 -61 -29 -76 -32 -162 -32 -86 0 -101 3 -162
            32 -93 44 -151 100 -196 191 -35 72 -37 81 -37 171 0 87 3 102 32 163 40 85
            97 147 172 186 78 42 122 52 213 48 61 -3 92 -11 142 -34z m-694 -536 c0 -8
            18 -50 40 -92 22 -43 40 -90 40 -106 l0 -28 -103 0 -103 0 -18 93 c-10 50 -20
            104 -23 120 l-5 27 86 0 c68 0 86 -3 86 -14z m1227 -13 c-3 -16 -13 -70 -23
            -120 l-18 -93 -103 0 -103 0 0 28 c0 16 18 63 40 106 22 42 40 84 40 92 0 11
            18 14 86 14 l87 0 -6 -27z m-1124 -430 c4 -32 9 -86 13 -120 l7 -63 -85 0 -84
            0 -18 93 c-10 50 -20 104 -23 120 l-5 27 94 0 95 0 6 -57z m347 -63 l0 -120
            -84 0 -83 0 -7 63 c-4 34 -9 88 -13 120 l-6 57 97 0 96 0 0 -120z m346 58 c-4
            -35 -9 -89 -13 -120 l-6 -58 -83 0 -84 0 0 120 0 120 96 0 97 0 -7 -62z m351
            35 c-3 -16 -13 -70 -23 -120 l-18 -93 -84 0 -85 0 7 63 c4 34 9 88 13 120 l6
            57 95 0 95 0 -6 -27z m-1202 -718 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55
            -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40
            25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z m830 -55 l0 -80 -325 0 -325 0 0
            80 0 80 325 0 325 0 0 -80z m290 55 c50 -49 15 -135 -55 -135 -19 0 -40 9 -55
            25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z"/>
            <path d="M1384 3259 l-103 -101 -27 21 c-56 44 -134 9 -134 -60 0 -26 12 -44
            63 -96 97 -96 98 -96 274 80 125 125 143 147 143 175 0 44 -38 82 -81 82 -27
            0 -48 -16 -135 -101z"/>
            <path d="M2425 1735 c-50 -49 -15 -135 55 -135 19 0 40 9 55 25 16 15 25 36
            25 55 0 19 -9 40 -25 55 -15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z"/>
            <path d="M3145 2375 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
            -80 80 -19 0 -40 -9 -55 -25z"/>
            <path d="M4105 2375 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
            -80 80 -19 0 -40 -9 -55 -25z"/>
        </g>
    </svg>
)

export default Logo
