import {createSlice} from '@reduxjs/toolkit'
const initialState = {
    products: [
        // {id: '', count: '', characteristic: '', price: '', name:'', thumb:''},
    ]
}



export const cartSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {

        addProduct: (state, action) => {
            const indexProductsWithPayloadId = state.products //find index
                .map((product, index) => {
                    return {
                        id: product.id,
                        index,
                        characteristic: product.characteristic
                    }
                })
                .filter((item) => item.id === action.payload.productId)
                .filter(item => item.characteristic === action.payload.characteristic)

            if(indexProductsWithPayloadId.length === 0)
                indexProductsWithPayloadId.push({index: -1})

            //console.log(action.payload, indexProductsWithPayloadId)

                for(let indexProductWithPayloadId of indexProductsWithPayloadId)
                    if(state.products[indexProductWithPayloadId.index]?.characteristic !== action.payload.characteristic || indexProductWithPayloadId.index < 0)
                        state.products.push({
                            id: parseInt(action.payload.productId),
                            price: action.payload.price,
                            name: action.payload.name,
                            characteristic: action.payload.characteristic,
                            thumb: action.payload.images[0].link,
                            count: 1
                        })
                    else
                        state.products[indexProductWithPayloadId.index].count++
        },
        editCount: (state, action) => {
            const index = action.payload.index
            const product = state.products[index]
            if (product.count > 0)
                product.count += action.payload.count
            if (product.count === 0)
                state.products.splice(index, 1)
            else
                state.products[index] = product
        },
        clearCart: (state)=>{
            state.products = []

        }
    }
})

export const {
    addProduct, editCount, clearCart
} = cartSlice.actions

export default cartSlice.reducer