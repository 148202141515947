import React, {useEffect, useState, useRef} from 'react';
import {Badge} from "@mui/material";
import {ReactComponent as EmptyCart} from "../../../assets/icons/EmptyCart.svg";
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import style from "./badge.module.css";

function CartIcon() {
    const badgeRef = useRef()
    const cart = useSelector(state => state.products.products)
    const[productCount,setProductCount] = useState(0)

    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    useEffect(()=>{
        const arr = [].concat(badgeRef.current.className
            .split(' ').filter(item => item === style.noAnimated || item === style.animated))

        const inCart = arr.slice()
        inCart.push(style.inCard)
        const outCart = arr.slice()
        outCart.push(style.outCart)

        badgeRef.current.className = inCart.join(' ')

        setTimeout(()=>{
            let count = 0
            for(let product of cart)
                count+=product.count
            badgeRef.current.className = outCart.join(' ')
            setProductCount(count)

        }, 200)

    }, [cart])

    return (
        <div style={{position:'relative', marginTop: '15px'}}>
            <Badge
                color={"warning"}
                style={{
                    position: 'absolute',
                    left: '50%'
                }}
                badgeContent={productCount}
                   anchorOrigin={{
                       vertical: 'top',
                       horizontal: 'right',
                   }}
                   ref={badgeRef}
                   >

            </Badge>
            <Link to={`cart${hasUtmCampaign}`}>

                <IconButton style={{padding: '0px', zIndex:'10', paddingRight:'20px'}}
                            size="large"
                            aria-label="display more actions"
                            edge="end"
                            color="inherit"
                >
                    <EmptyCart width="40" height="40"/>
                </IconButton>
            </Link>
        </div>

    );
}

export default CartIcon;