import React, {useState} from 'react';
import {Typography} from "@mui/material";
import {RandomFloatFromInterval} from "../../../../utils/Random";

function PriceProduct({hasSale, price}) {
    const [sale] = useState(RandomFloatFromInterval(1.1, 1.2));

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{mr:1,fontWeight: '500'}} fontSize="18pt" fontWeight="lg">
                {price} грн
            </Typography>
            {hasSale ?
                <div style={{
                    borderRadius: '3px',
                    fontSize: 'small',
                    wordWrap: ''
                }}>
                    <Typography
                        sx={{
                            textDecoration: 'line-through',
                            color: 'gray',
                            display: 'inline'
                        }}
                        level="body3">{parseFloat(price * sale).toFixed(0)} грн
                    </Typography>
                </div> : <div style={{height: '19px'}}></div>
            }
        </div>
    );
}

export default PriceProduct;