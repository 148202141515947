import React from 'react';
import {useSelector} from "react-redux";
import {Paper, TextField, Typography} from "@mui/material";

const Comment = ({userName, text, commentId}) => {
    return (
        <Paper key={commentId} style={{margin: '1em 0px', padding: '2em'}}>
            <Typography style={{fontWeight: 'bold'}}>{userName}</Typography>
            <Typography style={{wordWrap: 'break-word'}}>{text}</Typography>
        </Paper>
    );
};

export default Comment;