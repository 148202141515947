//Главная страница
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Banner1 from "../../assets/images/banners/krabzvolos.webp"
import Banner2 from "../../assets/images/banners/stupsverdlo.webp"
import CarsCard from "../../components/cards/CarsCard";
import ProductCard from "../../components/cards/ProductCard";
import styles from './home.module.css'
import ViewButton from "../../components/buttons/ViewButton";
import {useEffect, useState} from "react";

export default function Home(){

    const [products, setProducts] = useState([])
    const [error, setError] = useState()
    const [isLoading, setLoading] = useState(false)

    const productsUrl = `https://site.v2.yellowshop.in.ua/api/v1/products/all`

    useEffect(()=>{
        setLoading(true)
        fetch(productsUrl)
            .then(response => response.json())
            .then(productsList => setProducts(productsList))
            .catch(error => setError(error))
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const productsFiltered = products.filter(prod => prod.main)
        .map(prod => {prod.image = prod.images[0].link
        return prod
    })



    return (
    <div className={styles.home} style={{margin: '0 auto'}}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12} md={6}>
                <Card
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            {window.innerWidth > 768 ? (
                                <Typography
                                    sx={{
                                        color: '#ef821a',
                                        fontWeight: 600,
                                        fontSize: '18px', // Большой размер шрифта для ПК
                                    }}
                                    component="div"
                                    variant="h8"
                                >
                                    ОСОБЛИВЕ ДЛЯ ЖІНОК
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: '#ef821a',
                                        fontWeight: 600,
                                        fontSize: '13px', // Меньший размер шрифта для мобильных устройств
                                    }}
                                    component="div"
                                    variant="h8"
                                >
                                    ОСОБЛИВЕ ДЛЯ ЖІНОК
                                </Typography>
                            )}

                            {window.innerWidth > 768 ? (
                                <Typography
                                    mt={2}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '1.2',
                                    }}
                                >
                                    Ексклюзивна колекція жіночого штучного волосся, що дарує неперевершену можливість творити захоплюючі образи. Виготовлене з ультратонкого матеріалу, це волосся майже не відрізняється від природного, забезпечуючи при цьому легкість у використанні та догляді. Ваша найкраща версія чекає на вас – оберіть волосся та зачаруйте світ!
                                </Typography>
                            ) : (
                                <Typography
                                    mt={1}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                    sx={{
                                        fontSize: '12px',
                                        lineHeight: '1.5',
                                    }}
                                >
                                    Безмежні можливості для вашого стилю з унікальною колекцією різнобарвного волосся різного кріплення.
                                </Typography>
                            )}
                            <ViewButton link={'/hairs'} />
                        </CardContent>

                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: '50%', alignSelf: 'flex-end' }}
                        image={Banner1}
                        alt="for woman"
                    />
                </Card>


            </Grid>
            <Grid xs={12} md={6}>
                <Card sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            {window.innerWidth > 768 ? (
                                <Typography
                                    sx={{ color: '#ef821a', fontWeight: 600, fontSize: '18px' }}
                                    component="div"
                                    variant="h8"
                                >
                                    ОСОБЛИВЕ ДЛЯ ЧОЛОВІКІВ
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ color: '#ef821a', fontWeight: 600, fontSize: '13px' }}
                                    component="div"
                                    variant="h8"
                                >
                                    ОСОБЛИВЕ ДЛЯ ЧОЛОВІКІВ
                                </Typography>
                            )}
                            {window.innerWidth > 768 ? (
                                <Typography
                                    mt={2}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                    sx={{ fontSize: '16px', lineHeight: '1.2' }}
                                >
                                    Інструменти, що перетворять кожен проект у шедевр. Асортимент інструментів - ключ до майстерності та надійності. Відмінна якість матеріалів гарантує тривале використання, а розмаїття функцій дозволяє легко впоратися з будь-яким завданням. Оберіть інструменти та досягніть виняткових результатів у кожній роботі.
                                </Typography>
                            ) : (
                                <Typography
                                    mt={1}
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                    sx={{ fontSize: '12px', lineHeight: '1.5' }}
                                >
                                    Інструменти, що перетворять кожен проект у шедевр. Відмінна якість матеріалів гарантує тривале використання.
                                </Typography>
                            )}
                            <ViewButton link={'/tools'} />
                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: '50%' }}
                        image={Banner2}
                        alt="for man"
                    />
                </Card>
            </Grid>
            {productsFiltered.map(prod =>
                <Grid key={prod.productId} xs={12} sm={6} md={3}>
                    <ProductCard {...prod}/>
                </Grid>)}

            <Grid xs={12}>
                <CarsCard/>
            </Grid>
        </Grid>
    </div>
)

};