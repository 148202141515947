import * as React from "react"

const AllProductsIcon = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         {...props}
         fill="#ffffff"
         viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g>
            <path d="M504,416h-24V152c0-4.418-3.582-8-8-8H336V40c0-4.418-3.582-8-8-8H120c-4.418,0-8,3.582-8,8v192v141.414
            c-4.975-2.37-10.353-4.022-16-4.834V128c0-22.056-17.944-40-40-40H8c-4.418,0-8,3.582-8,8s3.582,8,8,8h48c13.234,0,24,10.767,24,24
            v240.581C52.899,372.475,32,395.838,32,424c0,30.879,25.122,56,56,56c28.162,0,51.525-20.898,55.419-48H328h108.305
            c-2.732,4.711-4.305,10.174-4.305,16c0,17.645,14.355,32,32,32s32-14.355,32-32c0-5.826-1.573-11.289-4.305-16H504
            c4.418,0,8-3.582,8-8S508.418,416,504,416z M464,160v256H336V232v-72H464z M320,48v176H128V48H320z M88,464
            c-22.056,0-40-17.944-40-40s17.944-40,40-40c22.045,0,39.982,17.927,39.999,39.968c0,0.011-0.002,0.021-0.002,0.032
            s0.002,0.021,0.002,0.032C127.982,446.073,110.045,464,88,464z M143.419,416c-1.732-12.054-7.315-22.876-15.461-31.182
            c0.027-0.269,0.042-0.542,0.042-0.819V240h192v176H143.419z M464,464c-8.822,0-16-7.178-16-16s7.178-16,16-16s16,7.178,16,16
            S472.822,464,464,464z"/>
            <path d="M104,416c-4.418,0-8,3.582-8,8c0,4.411-3.589,8-8,8s-8-3.589-8-8s3.589-8,8-8c4.418,0,8-3.582,8-8s-3.582-8-8-8
            c-13.234,0-24,10.767-24,24s10.766,24,24,24s24-10.767,24-24C112,419.582,108.418,416,104,416z"/>
            <path d="M144,160.002V200c0,4.418,3.582,8,8,8h144c4.418,0,8-3.582,8-8v-39.998v-0.005v-47.995v-0.005V72c0-4.418-3.582-8-8-8H152
            c-4.418,0-8,3.582-8,8v39.998v0.005v47.995V160.002z M204.686,80L288,163.314v25.372L179.314,80H204.686z M252.686,80L288,115.314
            v25.372L227.314,80H252.686z M268.686,192h-25.372L160,108.686V83.314L268.686,192z M220.686,192h-25.372L160,156.686v-25.372
            L220.686,192z M160,179.314L172.686,192H160V179.314z M288,92.686L275.314,80H288V92.686z"/>
            <path d="M352,336.002V392c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-23.998v-0.005v-47.995v-0.005v-47.995v-0.005v-47.995
            v-0.005V184c0-4.418-3.582-8-8-8h-80c-4.418,0-8,3.582-8,8v7.998v0.005v47.995v0.005v47.995v0.005v47.995V336.002z M396.686,192
            L432,227.314v25.372L371.314,192H396.686z M432,275.314v25.372l-64-64v-25.372L432,275.314z M432,323.314v25.372l-64-64v-25.372
            L432,323.314z M368,355.314L396.686,384H368V355.314z M419.314,384L368,332.686v-25.372l64,64V384H419.314z M432,204.686
            L419.314,192H432V204.686z"/>
            <path d="M304,303.998V264c0-4.418-3.582-8-8-8H152c-4.418,0-8,3.582-8,8v39.998v0.005v47.995v0.005V392c0,4.418,3.582,8,8,8h144
            c4.418,0,8-3.582,8-8v-39.998v-0.005v-47.995V303.998z M243.314,384L160,300.686v-25.372L268.686,384H243.314z M195.314,384
            L160,348.686v-25.372L220.686,384H195.314z M179.314,272h25.372L288,355.314v25.372L179.314,272z M227.314,272h25.372L288,307.314
            v25.372L227.314,272z M288,284.686L275.314,272H288V284.686z M160,371.314L172.686,384H160V371.314z"/>
        </g>
    </svg>
)

export default AllProductsIcon
