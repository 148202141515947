//Сторінка повернення товарів

import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from 'react';

export default function MapMarker() {

    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                <Grid container justifyItems="center" justifyContent="center" xs={12} mt={1} mb={2}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d334.950848791865!2d35.186397317351556!3d47.80845755802344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc5ff2c3497525%3A0x149343f5759b0588!2sNova%20Poshta%20%2314!5e0!3m2!1sru!2sua!4v1668196660176!5m2!1sru!2sua"
                            width="100%" height="600vh" styles="border:0;" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                </Grid>
            </Grid>
        </div>
    )
}