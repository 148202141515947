//Сторінка Політики конфіденційності
import styles from "../home/home.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import PublicOfferPoster from '../../assets/images/poster/public-offer.webp';
import Box from "@mui/material/Box";

export default function PublicOffer() {
    return (
        <div className={styles.home} style={{margin: '0 auto'}}>
            <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                <Grid container justifyItems="center" justifyContent="center" xs={12} mt={1} mb={2}>
                    <Paper elevation={3} style={{padding: 0}}>
                        <img src={PublicOfferPoster} alt="Договір публічної оферти" style={{height: 240,maxWidth: '100%', width: '100%'}}/>
                        <Box sx={{p: 2, maxWidth:'500px'}}>
                            <Typography variant="h6" mb={1} component="div">
                                Договір публічної оферти:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Цей документ є публічною офертою і містить умови та правила продажу товарів у інтернет-магазині Yellow Shop, які пропонуються потенційним покупцям на безпосередній основі. Цей договір є публічним та регулюється статтями 633 та 641 Цивільного кодексу України;
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                1. Загальні положення
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1.1. Назва інтернет-магазину: Yellow Shop.<br/>
                                1.2. Покупець - фізична або юридична особа, яка приймає умови цієї публічної оферти та здійснює замовлення товарів.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                2. Предмет договору
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                2.1. Предметом цього договору є продаж товарів, які представлені в інтернет-магазині Yellow Shop.<br/>
                                2.2. Каталог товарів, ціни та характеристики вказані на веб-сайті інтернет-магазину.<br/>
                                2.3. Інтернет-магазин залишає за собою право змінювати асортимент товарів, ціни, умови доставки та оплати без попереднього повідомлення.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                3. Розсилки та інформаційні повідомлення
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                3.1. Реєструючись на сайті та/або заповнюючи форми про особисті данні на сайті, покупець підтверджує, що надає про себе достовірні персональні дані, а також згоду на їх збір, використання та обробку*. Інтернет-магазин знімає з себе відповідальність за будь-які наслідки, що зумовлені наданням недостовірної інформації від користувача.<br/>
                                3.2. Інтернет-магазин бере на себе обов’язок дотримуватися законодавства України про захист персональних даних.<br/>
                                3.3. Надання покупцем адреси своєї електронної пошти, та/або номеру телефону, є автоматичною згодою на отримання розсилки від компанії в т.ч. вайбер-розсилки за вказаним номером, описаної в даному розділі.<br/>
                                3.4. Зазначені розсилки містять інформацію щодо майбутніх акцій з продажу товарів, розіграшів, знижок, спеціальних пропозицій, інформацій щодо замовлення.<br/>
                                3.5. Покупець будь-якої миті може відписатися від отримання розсилки.<br/>
                                3.6. Коли покупець здійснює замовлення, бере участь в акції, дослідженнях або іншим чином взаємодіє із інтернет-магазином, користувач тим самим надає свою згоду із даною Угодою користувача та положенням про обробку персональних даних.<br/>
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                4. Замовлення товарів
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                4.1. Замовлення товару здійснюється покупцем через оформлення замовлення на веб-сайті інтернет-магазину або за телефоном, вказаним на веб-сайті.<br/>
                                4.2. Після оформлення замовлення покупець отримує підтвердження замовлення на вказану ним контактну інформацію.<br/>
                                4.3. Інтернет-магазин має право відмовити в прийнятті замовлення без пояснення причини.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                5. Ціни та оплата
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                5.1. Ціни на товари вказані в українській гривні (грн) і включають всі податки та збори.<br/>
                                5.2. Вартість товару може змінюватися залежно від акцій, знижок або інших спеціальних пропозицій, які можуть бути дійсні протягом певного періоду часу.<br/>
                                5.3. Оплата за товари здійснюється покупцем виключно у валюті готівкою, електронним переказом або за допомогою інших доступних платіжних засобів, які пропонуються інтернет-магазином.<br/>
                                5.4. У разі обрання покупцем способу оплати електронним переказом, покупець зобов'язаний здійснити оплату протягом 24 годин після підтвердження замовлення. У разі неповної або несвоєчасної оплати замовлення, інтернет-магазин має право скасувати замовлення.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                6. Доставка товарів
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                6.1. Доставка товарів здійснюється відповідно до вибраного покупцем способу доставки, який надає інтернет-магазин.<br/>
                                6.2. Вартість доставки залежить від ваги, розміру товару та обраного способу доставки. Інформація про вартість доставки вказана на веб-сайті інтернет-магазину.<br/>
                                6.3. Інтернет-магазин не несе відповідальності за затримку доставки, спричинену обставинами, незалежними від контролю інтернет-магазину, такими як стихійні лиха, проблеми з транспортом або неправильно наданою адресою покупця.<br/>
                                6.4. При отриманні товару, покупець зобов'язаний перевірити його на відповідність опису, кількості та цілісності. У разі виявлення недоліків або пошкоджень товару, покупець повинен негайно повідомити про це інтернет-магазин, вказавши деталі проблеми.<br/>
                                6.5. У разі повернення товару через його неналежний стан або невідповідність опису, покупець має право на повернення коштів або обмін на аналогічний товар за умови, що товар не був використаний та збережено його оригінальну упаковку.<br/>
                                6.6. Інтернет-магазин не несе відповідальності за втрату або пошкодження товару під час доставки, якщо це сталося з вини служби доставки або покупця після отримання товару.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                7. Обмеження відповідальності
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                7.1. Інтернет-магазин не несе відповідальності за будь-які прямі або непрямі збитки, втрати або витрати, які виникли у зв'язку з використанням або неможливістю використання придбаних товарів.<br/>
                                7.2. Покупець несе відповідальність за правильність інформації, наданої під час оформлення замовлення, зокрема, за достовірність контактних даних, адреси доставки та іншої необхідної інформації.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body1" color="text.secondary" fontWeight="bold">
                                8. Заключні положення
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                8.1. Цей договір вважається укладеним з моменту здійснення покупцем замовлення на веб-сайті інтернет-магазину або за телефоном.<br/>
                                8.2. Інтернет-магазин залишає за собою право вносити зміни у цей договір без попереднього повідомлення. Зміни набувають чинності з моменту їх опублікування на веб-сайті інтернет-магазину. Покупці рекомендується періодично переглядати умови цього договору для ознайомлення зі змінами.<br/>
                                8.3. У разі виникнення спорів або розбіжностей, пов'язаних з виконанням цього договору, сторони зобов'язуються домовитись шляхом переговорів. Якщо переговори не призводять до досягнення взаємоприйнятного рішення, спори підлягають вирішенню відповідно до законодавства України.<br/>
                                8.4. Законодавство України застосовується до цього договору та всіх відносин, що виникають у зв'язку з його виконанням.<br/>
                                8.5. Якщо будь-яка з положень цього договору визнається недійсною, це не впливає на дійсність чи зобов'язування інших положень.<br/>
                                8.6. Цей договір є публічним, і його умови доступні для ознайомлення всім користувачам на веб-сайті інтернет-магазину Yellow Shop.
                            </Typography>
                            <Typography mt={1} mb={1} variant="body2" color="text.secondary">
                                Підтверджуючи свою згоду з умовами цього договору, покупець підтверджує, що він ознайомився та приймає усі умови та правила продажу товарів у інтернет-магазині Yellow Shop.
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}